import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';
import { ResponseObject } from '../../../../../../shared/models/response-object';
import { SelectionModel } from '../../../../../../shared/models/selection-model';
import { InvItem } from '../models/inv-item';

@Injectable({
  providedIn: 'root'
})
export class ItemService {

  endPoint = environment.endPoint + 'InvMsItem/';
  endPointIngredinetPrint = environment.endPoint + 'IngredientPrint/';
  httpHeader = { headers: new HttpHeaders() };

  constructor(private httpClient: HttpClient) { }

  setHeader() {
    if ((JSON.parse(localStorage.getItem('currentUser')!)) != null) {
      this.httpHeader = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'tenantId': (JSON.parse(localStorage.getItem('currentUser')!)).tenantId,
          'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('currentUser')!)).token
        })
      };
    }
  }

  getAll(groupId: number, advance: string, showAvailableQty: boolean): Observable<ResponseObject> {
    debugger;
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + 'GetAll?groupId=' + groupId + '&advance=' + advance + '&showAvailableQty=' + showAvailableQty, this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  getItemSearch(search: string): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + 'GetItemSearch?search=' + search, this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetStockCountItemSearch(search: string, branchId: number, storeId: number, date: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + 'GetStockCountItemSearch?search=' + search + '&branchId=' + branchId + '&storeId=' + storeId + '&date=' + date, this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  getMinimumLevel(branchId: number, storeId: number): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + 'GetMinimumLevel?branchId=' + branchId + '&storeId=' + storeId, this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  
  delete(obj): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + 'Delete?id=' + obj.id, this.httpHeader)
      .pipe(retry(1), catchError(this.processError))
  }

  getItemSearchWithTax(search: string, branchId: number): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + 'GetItemSearchWithTax?search=' + search + '&branchId=' + branchId, this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  getBranchTaxAllowed(id: number, branchId: number): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + 'GetBranchTaxAllowed?id=' + id + '&branchId=' + branchId, this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  getAllByAdvanceAndGroupId(advance, groupId): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPoint + 'GetAllByAdvanceAndGroupId',
      JSON.stringify({ groupID: groupId, branchID: advance }), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  getById(id?: any): Observable<ResponseObject> {
    this.setHeader();
    if (id == null)
      return this.httpClient.get<ResponseObject>(this.endPoint + 'GetById', this.httpHeader)
        .pipe(
          retry(1),
          catchError(this.processError)
        )
    else
      return this.httpClient.get<ResponseObject>(this.endPoint + 'GetById/' + id, this.httpHeader)
        .pipe(
          retry(1),
          catchError(this.processError)
        )
  }

  getLookup(): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + 'GetLookup', this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }


  RECALCULATEINGREDIENT(): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + 'RECALCULATEINGREDIENT', this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  add(obj: InvItem): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPoint + 'Create', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError))
  }

  update(obj: InvItem): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.put<ResponseObject>(this.endPoint + 'Update', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError))
  }

  getItemStockCount(obj): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPoint + 'GetItemStockCount', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError))
  }

  getItemStockCountByGroup(obj): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPoint + 'GetItemStockCountByGroup', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError))
  }


  getItemStockCountByDivision(obj): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPoint + 'GetItemStockCountByDivision', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError))
  }


  getItemStockCountByCategory(obj): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPoint + 'GetItemStockCountByCategory', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError))
  }


  getIngredients(id?: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + 'GetIngredients/' + id, this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }
  getItemIngredients(id?: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + 'GetItemIngredients/' + id, this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  deleteIngredients(id: number): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + 'DeleteIngredients/' + id, this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError))
  }

  UpdateIngredients(obj: InvItem): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.put<ResponseObject>(this.endPoint + 'UpdateIngredients', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError))
  }

  

  importIngredientXLS(obj:any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPoint + 'ImportIngredientExcel', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError))
  }


  getSalesItemByGroup(ids: Array<number>): Observable<SelectionModel[]> {
    this.setHeader();
    return this.httpClient.post<SelectionModel[]>(this.endPoint + 'GetSalesItemByGroup', JSON.stringify(ids), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }



  getIngredinetPrint(obj: InvItem): Observable<ResponseObject> {
    this.setHeader();

    return this.httpClient.post<ResponseObject>(this.endPointIngredinetPrint + 'GetIngredient', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }


  getIngredinetPrintWithOutCoast(obj: InvItem): Observable<ResponseObject> {
    this.setHeader();

    return this.httpClient.post<ResponseObject>(this.endPointIngredinetPrint + 'GetIngredientWithOutCoast', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }



  importXLS(obj: any[]): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPoint + 'ImportXLS', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError))
  }









  processError(err: any) {
    let message = '';
    if (err.error instanceof ErrorEvent) {
      message = err.error.message;
    } else {
      message = `Error Code: ${err.status}\nMessage: ${err.message}`;
    }
    return throwError(message);
  }

}
