import { Injectable } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';
import { ResponseObject } from '../../../../../../shared/models/response-object';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class StoreExpireService {
  endPoint = environment.endPoint + 'InvItemStoreExpire/';
  endPointStoreExpireToPrint = environment.endPoint + 'StoreExpirePrint/';

  httpHeader = { headers: new HttpHeaders() };

  constructor(private httpClient: HttpClient,private pipeDate:DatePipe) { }

  setHeader() {
    if ((JSON.parse(localStorage.getItem('currentUser')!)) != null) {
      this.httpHeader = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'tenantId': (JSON.parse(localStorage.getItem('currentUser')!)).tenantId,
          'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('currentUser')!)).token
        })
      };
    }
  }




  GetStoreExpireBySearchDTO(obj: any): Observable<ResponseObject> {
    this.setHeader();
    obj.storeId =  obj.storeId =="null" ?null :  obj.storeId ;
    obj.toDate=( this.pipeDate.transform(obj.toDate, 'mediumDate'));
    return this.httpClient.post<ResponseObject>(this.endPoint + 'GetStoreExpireBySearchDTO', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }



  GetStoreExpirePrint(obj: any): Observable<ResponseObject> {
    this.setHeader();
    obj.storeId =  obj.storeId =="null" ?null :  obj.storeId ;
    obj.toDate=( this.pipeDate.transform(obj.toDate, 'mediumDate'));
    return this.httpClient.post<ResponseObject>(this.endPointStoreExpireToPrint + 'GetItemExpirePrintDTO', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }


  get(branchId, storeId,itemId): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + "Get?branchId= "+branchId+ "&storeId="+storeId+"&itemId="+ itemId, this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }


  adjustItemStoreExpire(obj: any): Observable<ResponseObject> {
    this.setHeader();
    obj.forEach(element => {
      element.id=0;
      element.expireDate = (this.pipeDate.transform(element.expireDate, 'mediumDate'));
    });
   
    return this.httpClient.post<ResponseObject>(this.endPoint + 'AdjustItemStoreExpire', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError))
  }

  delete(branchId, storeId, invItemId): Observable<ResponseObject> {
      this.setHeader();
      return this.httpClient.get<ResponseObject>(this.endPoint + 'Delete?branchId=' +branchId + '&storeId=' +storeId + '&invItemId='+ invItemId, this.httpHeader)
        .pipe(retry(1), catchError(this.processError))
  }



  processError(err: any) {
    let message = '';
    if (err.error instanceof ErrorEvent) {
      message = err.error.message;
    } else {
      message = `Error Code: ${err.status}\nMessage: ${err.message}`;
    }
    return throwError(message);
  }



}

