import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbDialogService, NbMediaBreakpointsService, NbMenuItem, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { filter, map, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { AuthenticationService } from '../../../../shared/services/authentication.service'
import { Router } from '@angular/router';

import { ProfileComponent } from '../../../pages/modules/backOffice/profile/components/profile/profile.component';
import { WebUserService } from '../../../pages/modules/backOffice/webUser/WebUserService';
import { InventoryItemExpiryComponent } from '../../../pages/modules/inventory-management/inventoryItem/components/inventory-item-expiry/inventory-item-expiry.component';
import { TosterService } from '../../../../shared/services/toster.service';
import { MoreReportService } from '../../../pages/modules/inventory-management/reports/services/more-report.service';
import { environment } from '../../../../environments/environment';
import { InventoryReportService } from '../../../pages/modules/inventory-management/reports/services/inventory-report.service';
import { ChangeCompanyComponent } from '../../../pages/modules/accounting/changeCompany/components/change-company/change-company.component';
import { EndOfYearComponent } from '../../../pages/modules/accounting/endOfYear/components/end-of-year/end-of-year.component';
import { ChangeYearListComponent } from '../../../pages/modules/accounting/changeYear/components/change-year-list/change-year-list.component';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  user: any;
  userMenu = [];
  currentYear: string=null;
  currentCompany: string=null;
  menuDialogeOpen: boolean = false;
  notifictionNo: number = null;
  currentTheme = 'dark';
  tag = 'my-context-menu';
  notificTag = 'my-notificcontext-menu';
  cityPermision: boolean;
  zonePermision: boolean;
  deliverychargePermision: boolean;
  settingMenu: NbMenuItem[] = [];
  notificationMenu: NbMenuItem[] = [];
  userPictureOnly: boolean = false;
  private destroy$: Subject<void> = new Subject<void>();


  constructor(private router: Router,
    private dialogService: NbDialogService,
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private tosterService: TosterService,
    private authenticationService: AuthenticationService, private moreReportService: MoreReportService,
    private InventoryReportService: InventoryReportService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService) {
      if((((JSON.parse(localStorage.getItem("currentUser")!).webUser.webAccessaRole.webAccessPermissions.filter(l => l.permissionName == "Accounting")))).length == 1)
      {
        this.authenticationService.currentYear.subscribe(l=> this.currentYear=(l.year).toString());
        this.authenticationService.currentCompany.subscribe(l=> this.currentCompany=l.company.companyName);
      }
    if (this.authenticationService.currentUserValue.webUser.isAdmin == true) 
    {
      this.userMenu = [
        { title: 'Profile', icon: 'person' },
        { title: 'Web User', link: '/pages/modules/backoffice/webUser', icon: 'person-add-outline' },
        { title: 'Web Access', link: '/pages/modules/backoffice/accessRole', icon: 'person-done-outline' },
        { title: 'Log out', icon: 'log-out' },
      ];
      if ((((JSON.parse(localStorage.getItem("currentUser")!).webUser.webAccessaRole.webAccessPermissions.filter(l => l.permissionName == "Inventory Management")))).length == 1
        && (((JSON.parse(localStorage.getItem("currentUser")!).webUser.webAccessaRole.webAccessPermissions.filter(l => l.permissionName == "Accounting")))).length == 1)
        this.settingMenu = [
          {
            title: 'Back Office', expanded: false,
            children: [
              { title: 'Company', link: '/pages/modules/backoffice/company' },
              { title: 'Branch', link: '/pages/modules/backoffice/branch' },
              { title: 'Back Office Config', link: '/pages/modules/backoffice/barnchConfig' },
              { title: 'Delivery Charge', link: '/pages/modules/backoffice/deliveryCharge' },
              { title: 'City', link: '/pages/modules/backoffice/city' },
              { title: 'Zone', link: '/pages/modules/backoffice/zone' },
            ]
          },
          {
            title: 'Inventory', expanded: false,
            children: [
              { title: 'Inventory Config', link: '/pages/modules/inventory/config' }
            ]
          },
          {
            title: 'Accounting', expanded: false,
            children: [
              { title: 'Company', link: '/pages/modules/accounting/company' },
              { title: 'Accounting Config', link: '/pages/modules/accounting/config' },
              { title: 'Change Company'},
              { title: 'End Of Year' },
              { title: 'Change Year' }
            ]
          }
        ];
      else if ((((JSON.parse(localStorage.getItem("currentUser")!).webUser.webAccessaRole.webAccessPermissions.filter(l => l.permissionName == "Inventory Management")))).length == 1
        && (((JSON.parse(localStorage.getItem("currentUser")!).webUser.webAccessaRole.webAccessPermissions.filter(l => l.permissionName == "Accounting")))).length == 0)
        this.settingMenu = [
          {
            title: 'Back Office', expanded: false,
            children: [
              { title: 'Company', link: '/pages/modules/backoffice/company' },
              { title: 'Branch', link: '/pages/modules/backoffice/branch' },
              { title: 'Back Office Config', link: '/pages/modules/backoffice/barnchConfig' },
              { title: 'Delivery Charge', link: '/pages/modules/backoffice/deliveryCharge' },
              { title: 'City', link: '/pages/modules/backoffice/city' },
              { title: 'Zone', link: '/pages/modules/backoffice/zone' },
            ]
          },
          {
            title: 'Inventory', expanded: false,
            children: [
              { title: 'Inventory Config', link: '/pages/modules/inventory/config' }
            ]
          }
        ];
      else if ((((JSON.parse(localStorage.getItem("currentUser")!).webUser.webAccessaRole.webAccessPermissions.filter(l => l.permissionName == "Inventory Management")))).length == 0
        && (((JSON.parse(localStorage.getItem("currentUser")!).webUser.webAccessaRole.webAccessPermissions.filter(l => l.permissionName == "Accounting")))).length == 1)
        this.settingMenu = [
          {
            title: 'Back Office', expanded: false,
            children: [
              { title: 'Company', link: '/pages/modules/backoffice/company' },
              { title: 'Branch', link: '/pages/modules/backoffice/branch' },
              { title: 'Back Office Config', link: '/pages/modules/backoffice/barnchConfig' },
              { title: 'Delivery Charge', link: '/pages/modules/backoffice/deliveryCharge' },
              { title: 'City', link: '/pages/modules/backoffice/city' },
              { title: 'Zone', link: '/pages/modules/backoffice/zone' },
            ]
          },
          {
            title: 'Accounting', expanded: false,
            children: [
              { title: 'Company', link: '/pages/modules/accounting/company' },
              { title: 'Accounting Config', link: '/pages/modules/accounting/config' },
              { title: 'Change Company' },
              { title: 'End Of Year' },
              { title: 'Change Year' }
            ]
          }
        ];
      else  if ((((JSON.parse(localStorage.getItem("currentUser")!).webUser.webAccessaRole.webAccessPermissions.filter(l => l.permissionName == "Inventory Management")))).length == 0
      && (((JSON.parse(localStorage.getItem("currentUser")!).webUser.webAccessaRole.webAccessPermissions.filter(l => l.permissionName == "Accounting")))).length == 0)
        this.settingMenu = [
          {
            title: 'Back Office', expanded: false,
            children: [
              { title: 'Company', link: '/pages/modules/backoffice/company' },
              { title: 'Branch', link: '/pages/modules/backoffice/branch' },
              { title: 'Back Office Config', link: '/pages/modules/backoffice/barnchConfig' },
              { title: 'Delivery Charge', link: '/pages/modules/backoffice/deliveryCharge' },
              { title: 'City', link: '/pages/modules/backoffice/city' },
              { title: 'Zone', link: '/pages/modules/backoffice/zone' },
            ]
          },
        ];
    } 
    else
    {
      this.userMenu = [
        { title: 'Profile', icon: 'person' },
        { title: 'Log out', icon: 'log-out' },
      ];
      if ((((JSON.parse(localStorage.getItem("currentUser")!).webUser.webAccessaRole.webAccessPermissions.filter(l => l.permissionName == "Inventory Management")))).length == 1
        && (((JSON.parse(localStorage.getItem("currentUser")!).webUser.webAccessaRole.webAccessPermissions.filter(l => l.permissionName == "Accounting")))).length == 1)
        this.settingMenu = [
          {
            title: 'Back Office', expanded: false,
            children: [
              { title: 'Delivery Charge', link: '/pages/modules/backoffice/deliveryCharge' },
              { title: 'City', link: '/pages/modules/backoffice/city' },
              { title: 'Zone', link: '/pages/modules/backoffice/zone' },
            ]
          },
          {
            title: 'Inventory', expanded: false,
            children: [
              { title: 'Inventory Config', link: '/pages/modules/inventory/config' }
            ]
          },
          {
            title: 'Accounting', expanded: false,
            children: [
              { title: 'Accounting Config', link: '/pages/modules/accounting/config' },
              { title: 'Change Company'},
              { title: 'End Of Year' },
              { title: 'Change Year' }
            ]
          }
        ];
      else if ((((JSON.parse(localStorage.getItem("currentUser")!).webUser.webAccessaRole.webAccessPermissions.filter(l => l.permissionName == "Inventory Management")))).length == 1
        && (((JSON.parse(localStorage.getItem("currentUser")!).webUser.webAccessaRole.webAccessPermissions.filter(l => l.permissionName == "Accounting")))).length == 0)
        this.settingMenu = [
          {
            title: 'Back Office', expanded: false,
          children: [
            { title: 'Delivery Charge', link: '/pages/modules/backoffice/deliveryCharge' },
            { title: 'City', link: '/pages/modules/backoffice/city' },
            { title: 'Zone', link: '/pages/modules/backoffice/zone' },
          ]
          },
          {
            title: 'Inventory', expanded: false,
            children: [
              { title: 'Inventory Config', link: '/pages/modules/inventory/config' }
            ]
          }
        ];
      else if ((((JSON.parse(localStorage.getItem("currentUser")!).webUser.webAccessaRole.webAccessPermissions.filter(l => l.permissionName == "Inventory Management")))).length == 0
        && (((JSON.parse(localStorage.getItem("currentUser")!).webUser.webAccessaRole.webAccessPermissions.filter(l => l.permissionName == "Accounting")))).length == 1)
        this.settingMenu = [
          {
            title: 'Back Office', expanded: false,
          children: [
            { title: 'Delivery Charge', link: '/pages/modules/backoffice/deliveryCharge' },
            { title: 'City', link: '/pages/modules/backoffice/city' },
            { title: 'Zone', link: '/pages/modules/backoffice/zone' },
          ]
          },
          {
            title: 'Accounting', expanded: false,
            children: [
              { title: 'Accounting Config', link: '/pages/modules/accounting/config' },
              { title: 'Change Company' },
              { title: 'End Of Year' },
              { title: 'Change Year' }
            ]
          }
        ];
      else  if ((((JSON.parse(localStorage.getItem("currentUser")!).webUser.webAccessaRole.webAccessPermissions.filter(l => l.permissionName == "Inventory Management")))).length == 0
      && (((JSON.parse(localStorage.getItem("currentUser")!).webUser.webAccessaRole.webAccessPermissions.filter(l => l.permissionName == "Accounting")))).length == 0)
        this.settingMenu = [
          {
            title: 'Back Office', expanded: false,
          children: [
            { title: 'Delivery Charge', link: '/pages/modules/backoffice/deliveryCharge' },
            { title: 'City', link: '/pages/modules/backoffice/city' },
            { title: 'Zone', link: '/pages/modules/backoffice/zone' },
          ]
          },
        ];
    } 



    if ((((JSON.parse(localStorage.getItem("currentUser")!).webUser.webAccessaRole.webAccessPermissions.filter(l => l.permissionName == "Inventory Management")))).length == 1) {
      if (((JSON.parse(localStorage.getItem("currentUser")!).invNotificationCountsDTO).itemStoreExpireCount) > 0 && (JSON.parse(localStorage.getItem("currentUser")!).invExpanderConfigs.find(l => l.name == "General").invConfigs.find(l => l.discription == "Expiry Date Warning").status)) {
        this.notifictionNo = 1;
        this.notificationMenu.push({ title: ((JSON.parse(localStorage.getItem("currentUser")!).invNotificationCountsDTO).itemStoreExpireCount) + ' Item(s) have expired !' })
      }
      if (((JSON.parse(localStorage.getItem("currentUser")!).invNotificationCountsDTO).itemStoreMinimumCount) > 0 && (JSON.parse(localStorage.getItem("currentUser")!).invExpanderConfigs.find(l => l.name == "General").invConfigs.find(l => l.discription == "Stock Level Warning ").status)) {
        this.notifictionNo += 1;
        this.notificationMenu.push({ title: ((JSON.parse(localStorage.getItem("currentUser")!).invNotificationCountsDTO).itemStoreMinimumCount) + ' Item(s) have low Stock Level !' })
      }
    }
  }
  onMenuClick(menuItem: any) {
    console.log("menuItem",menuItem)
    this.settingMenu = this.settingMenu.map(item => {
      // Expand only the clicked item, collapse others
      if (item.title === menuItem.title) {
        return { ...item, expanded: !item.expanded };
      } else {
        return { ...item, expanded: false };
      }
    });
  }
  ngOnInit() {
    this.user = { name: this.authenticationService.currentUserValue.webUser.userName, picture: this.authenticationService.currentUserValue.webUser.userPicture };
    this.currentTheme = this.themeService.currentTheme;
    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

    this.menuService.onItemClick()
      .subscribe((event) => {
        this.onContecxtItemSelection(event.item.title);
      });

  }


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  onContecxtItemSelection(title) {

    if (title == 'Log out') {
      this.authenticationService.signup()
        .subscribe(
          res => {
            this.router.navigate(['/sign-in']);
          },
          error => { this.router.navigate(['/sign-in']); }
        );

    } else if (title == 'Profile' && !this.menuDialogeOpen) {
      this.openProfileDialog();
    }
    else if (title.includes(' Item(s) have expired !') && !this.menuDialogeOpen) {
      this.openInventoryItemExpiryDialug();
    }
    else if (title.includes(' Item(s) have low Stock Level !') && !this.menuDialogeOpen) {
      this.getInventoryItemLevelReportDTO();
    }
    else if (title=='Change Company') {
      this.openChangeCompanyDialug();
    }
    else if (title=='End Of Year') {
      this.openEndOfYearDialug();
    }
    else if (title=='Change Year') {
      this.openChangeYearDialug();
    }

    
    
  }

  openProfileDialog() {
    this.menuDialogeOpen = true;
    this.dialogService.open(ProfileComponent, {
      closeOnBackdropClick: false,
      autoFocus: true,
    })
      .onClose.subscribe(returnedObj => {
        this.menuDialogeOpen = false;
      });
  }

  openInventoryItemExpiryDialug() {
    this.menuDialogeOpen = true;
    this.dialogService.open(InventoryItemExpiryComponent, {
      closeOnBackdropClick: false, autoFocus: true, dialogClass: 'model-full'
    }).onClose.subscribe(returnedObj => { this.menuDialogeOpen = false; });

  }

  endOfYear
  openChangeCompanyDialug() {
    this.menuDialogeOpen = true;
    this.dialogService.open(ChangeCompanyComponent, {
      closeOnBackdropClick: false, autoFocus: true, dialogClass: 'model-full'
    }).onClose.subscribe(returnedObj => { this.menuDialogeOpen = false; });

  }

  openEndOfYearDialug() {
    this.menuDialogeOpen = true;
    this.dialogService.open(EndOfYearComponent, {
      closeOnBackdropClick: false, autoFocus: true, dialogClass: 'model-full'
    }).onClose.subscribe(returnedObj => { this.menuDialogeOpen = false; });
  }

  
  openChangeYearDialug() {
    this.menuDialogeOpen = true;
    this.dialogService.open(ChangeYearListComponent, {
      closeOnBackdropClick: false, autoFocus: true, dialogClass: 'model-full'
    }).onClose.subscribe(returnedObj => { this.menuDialogeOpen = false; });
  }


  getInventoryItemLevelReportDTO() {

    // alert("Here");
    this.menuDialogeOpen = true;
    this.InventoryReportService.GetReorderingItemReportDTOAll("Reordering Item Report")
      .subscribe(res => {
        window.open(environment.reportPath + '/report/' + res.data, '_blank');
        this.menuDialogeOpen = false;
      },
        error => this.HandelingError(error)
      );
  }

  HandelingError(error: string) {
    if (error.includes("401") || error.includes("500") || error.includes("0")) {
      this.tosterService.showToast("warning", "Your Session Has Expired Please Login In");
      setTimeout(() => {
        this.authenticationService.Logout();
        this.router.navigate(['/sign-in']);
      }, 3000);
    }
  }
}