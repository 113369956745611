import { DatePipe } from '@angular/common';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { ResponseObject } from '../../../../../../shared/models/response-object';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MoreReportService {

  endPointInventoryMoreSupplierListReport = environment.endPoint + 'InventoryMoreSupplierListReport/';
  endPointInventoryMoreItemTreeViewReport = environment.endPoint + 'InventoryMoreItemTreeViewReport/';
  endPointInventoryMoreItemReport = environment.endPoint + 'InventoryMoreItemReport/';
  endPointInventoryMoreItemDetailReport = environment.endPoint + 'InventoryMoreItemDetailReport/';
  endPointInventoryMoreItemSellingPriceReport = environment.endPoint + 'InventoryMoreItemSellingPriceReport/';
  endPointInventoryMoreItemUsageOnRecipeReport = environment.endPoint + 'InventoryMoreItemUsageOnRecipeReport/';
  endPointInventoryMoreItemUsageOnProductionReport = environment.endPoint + 'InventoryMoreItemUsageOnProductionReport/';
  endPointInventoryMoreItemIngeredientList = environment.endPoint + 'InventoryMoreItemIngeredientList/';
  endPointInventoryMoreItemSalesItemPriceReport = environment.endPoint + 'InventoryMoreItemSalesItemPriceReport/';
  endPointInventoryMoreItemNotUsedReport = environment.endPoint + 'InventoryMoreItemNotUsedReport/';
  endPointInventoryMoreSalesItemWithOutRecipeReport = environment.endPoint + 'InventoryMoreSalesItemWithOutRecipeReport/';
  endPointInventoryMoreItemInventoryItemLevel = environment.endPoint + 'InventoryMoreItemInventoryItemLevel/';
  endPointInventoryMoreInventorySalesItemProft = environment.endPoint + 'InventoryMoreInventorySalesItemProft/';

  httpHeader = { headers: new HttpHeaders() };

  constructor(private httpClient: HttpClient, private datepipe: DatePipe) { }

  setHeader() {
    if ((JSON.parse(localStorage.getItem('currentUser')!)) != null) {
      this.httpHeader = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'tenantId': (JSON.parse(localStorage.getItem('currentUser')!)).tenantId,
          'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('currentUser')!)).token
        })
      };
    }
  }
  parseEODReport(obj: any) {
    if (obj.eODDate != null && obj.eODDate != "")
      obj.eODDate = (this.datepipe.transform((obj.eODDate).replace(/(\d{2})-(\d{2})-(\d{4})/, "$2-$1-$3"), 'yyyy-MM-dd', 'es-ES'));
    else {
      obj.fromDate = new Date(this.datepipe.transform((new Date(obj.fromDate)), 'yyyy-MM-dd'));
      obj.toDate = new Date(this.datepipe.transform((new Date(obj.toDate)), 'yyyy-MM-dd'));
    }
  }

  processError(err: any) {
    let message = '';
    if (err.error instanceof ErrorEvent) {
      message = err.error.message;
    } else {
      message = `Error Code: ${err.status}\nMessage: ${err.message}`;
    }
    return throwError(message);
  }

  //#region PDF

  GetSupplierListReportDTO(obj: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPointInventoryMoreSupplierListReport + 'GetSupplierListReportDTO', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetInventoryTreeViewReportDTO(obj: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPointInventoryMoreItemTreeViewReport + 'GetInventoryTreeViewReportDTO', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }


  GetInventoryItemReportDTO(obj: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPointInventoryMoreItemReport + 'GetInventoryItemReportDTO', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetInventoryItemDetailReportDTO(obj: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPointInventoryMoreItemDetailReport + 'GetInventoryItemDetailReportDTO', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetInventoryItemSellingPriceReportDTO(obj: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPointInventoryMoreItemSellingPriceReport + 'GetInventoryItemSellingPriceReportDTO', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetInventoryItemUsageOnRecipeReportDTO(obj: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPointInventoryMoreItemUsageOnRecipeReport + 'GetInventoryItemUsageOnRecipeReportDTO', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetInventoryItemUsageOnProductionReportDTO(obj: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPointInventoryMoreItemUsageOnProductionReport + 'GetInventoryItemUsageOnProductionReportDTO', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetInventoryIngredientListReportDTO(obj: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPointInventoryMoreItemIngeredientList + 'GetInventoryIngredientListReportDTO', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetInventorySalesItemRecipeReportDTO(obj: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPointInventoryMoreItemSalesItemPriceReport + 'GetInventorySalesItemRecipeReportDTO', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetInventoryItemNotUsedReportDTO(obj: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPointInventoryMoreItemNotUsedReport + 'GetInventoryItemNotUsedReportDTO', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetInventorySalesItemWithOutRecipeReportDTO(obj: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPointInventoryMoreSalesItemWithOutRecipeReport + 'GetInventorySalesItemWithOutRecipeReportDTO', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetInventoryItemLevelReportDTO(obj: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPointInventoryMoreItemInventoryItemLevel + 'GetInventoryItemLevelReportDTO', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetInventorySalesItemProfitReportDTO(obj: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPointInventoryMoreInventorySalesItemProft + 'GetInventorySalesItemProfitReportDTO', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }
  //#endregion


  //#region  XLS


  GetSupplierListReportDTOXLS(obj: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPointInventoryMoreSupplierListReport + 'GetSupplierListReportDTOXLS', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetInventoryTreeViewReportDTOXLS(obj: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPointInventoryMoreItemTreeViewReport + 'GetInventoryTreeViewReportDTOXLS', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetInventoryItemReportDTOXLS(obj: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPointInventoryMoreItemReport + 'GetInventoryItemReportDTOXLS', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetInventoryItemDetailReportDTOXLS(obj: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPointInventoryMoreItemDetailReport + 'GetInventoryItemDetailReportDTOXLS', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetInventoryItemSellingPriceReportDTOXLS(obj: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPointInventoryMoreItemSellingPriceReport + 'GetInventoryItemSellingPriceReportDTOXLS', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetInventoryItemUsageOnRecipeReportDTOXLS(obj: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPointInventoryMoreItemUsageOnRecipeReport + 'GetInventoryItemUsageOnRecipeReportDTOXLS', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetInventoryItemUsageOnProductionReportDTOXLS(obj: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPointInventoryMoreItemUsageOnProductionReport + 'GetInventoryItemUsageOnProductionReportDTOXLS', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetInventoryIngredientListReportDTOXLS(obj: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPointInventoryMoreItemIngeredientList + 'GetInventoryIngredientListReportDTOXLS', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetInventorySalesItemRecipeReportDTOXLS(obj: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPointInventoryMoreItemSalesItemPriceReport + 'GetInventorySalesItemRecipeReportDTOXLS', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetInventoryItemNotUsedReportDTOXLS(obj: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPointInventoryMoreItemNotUsedReport + 'GetInventoryItemNotUsedReportDTOXLS', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetInventorySalesItemWithOutRecipeReportDTOXLS(obj: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPointInventoryMoreSalesItemWithOutRecipeReport + 'GetInventorySalesItemWithOutRecipeReportDTOXLS', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetInventoryItemLevelReportDTOXLS(obj: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPointInventoryMoreItemInventoryItemLevel + 'GetInventoryItemLevelReportDTOXLS', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetInventorySalesItemProfitReportDTOXLS(obj: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPointInventoryMoreInventorySalesItemProft + 'GetInventorySalesItemProfitReportDTOXLS', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  //#endregion

}
