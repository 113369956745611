<div class="model-full">
    <nb-card  [nbSpinner]="loading" nbSpinnerStatus="warning">

        <nb-card-header>
            <h6 style="float:left;color: #fc9b04">Change Year</h6>
            <button style="float:right;" nbButton status="danger" size="medium" (click)="close(null)"><nb-icon
                    icon="close-outline"></nb-icon></button>
        </nb-card-header>

        <nb-card-body>
            <form [formGroup]="form">

                <div class="row show-grid">
                    <div class="col-12  col-sm-2  col-md-2">
                        <button  nbButton status="primary" size="medium" style="margin-top: 25px;float: right;"
                            (click)="addNewYear('minus')"><nb-icon icon="minus-outline"></nb-icon></button>
                    </div>

                    <!-- Year form-group -->
                    <div class="col-12  col-sm-8  col-md-8">
                        <div class="form-group">
                            <label for="year" class="label">Select Year / Add new Year</label>
                            <nb-select formControlName="year" fullWidth placeholder="year"
                                (selectedChange)="yearSelectedChange($event)" size="medium">
                                <nb-option *ngFor="let year of yearList" [value]="year.id">
                                    {{year.name}}</nb-option>
                            </nb-select>
                        </div>
                    </div>

                    <div class="col-12  col-sm-2  col-md-2">
                        <button  nbButton status="primary" size="medium" style="margin-top: 25px;float: left;;"
                            (click)="addNewYear('plus')"><nb-icon icon="plus-outline"></nb-icon></button>
                    </div>
                </div>

            </form>
        </nb-card-body>

    </nb-card>

</div>