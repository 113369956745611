<div class="dialog-container">
    <nb-card>
        <nb-card-header>
            <h6 style="float:left;color: #fc9b04">Profile</h6>
            <button style="float:right;" nbButton status="danger" size="medium" (click)="close()">
                <nb-icon icon="close-outline"></nb-icon>
            </button>
        </nb-card-header>

        <nb-card-body>

            <nb-tabset fullWidth>

                <nb-tab tabTitle="User Info">
                    <form [formGroup]="form">

                        <div class="row show-grid">
                            <div class="col align-self-center" style="text-align: center; width: 20% ;">
                                <div *ngIf="userPictureSrc && userPictureSrc !== ''">
                                    <img [src]="userPictureSrc" [alt]="'no image'"
                                        style="height: 4.5rem; ; border-radius: 50%; top:0px;">
                                    <button id="clearLogoBtn" nbButton size="tiny" (click)="clearLogo()">
                                        <nb-icon icon="close"></nb-icon>
                                    </button>
                                </div>

                                <div *ngIf="userPictureSrc == ''|| userPictureSrc == null" style="margin-top: 10px;margin-bottom: 10px ;">
                                    <label id="picture">
                                        <i class="fa fa-image fa-3x" aria-hidden="true"></i>
                                        <input type="file" style="display: none;" accept="image/*"
                                            (change)="onChangePicture($event)">
                                    </label>
                                    <div class="error" *ngIf="userPictureSrcSize">
                                        Maximum Image Size 500KB
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="row show-grid">

                            <!-- Email form-group -->
                            <div class="col-12  col-sm-6 col-md-6">
                                <div class="form-group">
                                    <label for="email" class="label">Email</label>
                                    <input #email type="email" nbInput fullWidth id="email" placeholder="Email"
                                        formControlName="email" class="form-control" [attr.disabled]="true"
                                        [ngClass]="{ 'is-invalid': submitted && formValidation.email.errors }">
                                    <div *ngIf="(formValidation['email'].touched && formValidation['email'].invalid) || submitted"
                                        class="invalid-feedbackr">
                                        <div class="error" *ngIf="formValidation['email'].errors?.['required']">
                                            Email Is Required.</div>
                                    </div>
                                    <div *ngIf="submitted && formValidation['email'].errors?.['email'] " class="error">
                                        Inavlid Email.
                                    </div>
                                </div>
                            </div>

                            <!-- Creation Date form-group -->
                            <div class="col-12  col-sm-6 col-md-6">
                                <div class="form-group">
                                    <label class="label">Creation Date</label>
                                    <input nbInput placeholder="Creation Date" formControlName="creationDate"
                                        [nbDatepicker]="creationDateTimePicker" fullWidth disabled>
                                    <nb-datepicker #creationDateTimePicker></nb-datepicker>
                                </div>
                            </div>

                        </div>

                        <div class="row show-grid">
                            <!-- User Name form-group -->
                            <div class="col-12  col-sm-6  col-md-6">
                                <div class="form-group">
                                    <label for="userName" class="label">User Name</label>
                                    <input type="text" nbInput fullWidth id="userName" placeholder="User Name"
                                        formControlName="userName" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && formValidation.userName.errors }">
                                    <div *ngIf="(formValidation['userName'].touched && formValidation['userName'].invalid) || submitted"
                                        class="invalid-feedbackr">
                                        <div class="error"
                                            *ngIf="formValidation['userName'].errors ">
                                            User Name is required.</div>
                                    </div>
                                </div>
                            </div>

                            <!-- Phone form-group -->
                            <div class="col-12  col-sm-6  col-md-6">
                                <div class="form-group">
                                    <label for="phoneNumber" class="label">Phone</label>
                                    <input #phoneNumber type="text" nbInput fullWidth id="phoneNumber"
                                        placeholder="Phone" formControlName="phoneNumber" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && formValidation.phoneNumber.errors }">
                                    <div *ngIf="(formValidation['phoneNumber'].touched && formValidation['phoneNumber'].invalid) || submitted"
                                        class="invalid-feedbackr">
                                        <div class="error"
                                            *ngIf="formValidation['phoneNumber'].errors">
                                            Phone Is Required.</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row show-grid" style="border-top: 1px solid #151a30;padding-top: 10px; margin-top: 10px;">
                            <button type="submit" nbButton status="primary" size="medium" (click)="onSubmit()">
                                <nb-icon icon="save"></nb-icon>
                            </button>
                        </div>

                    </form>
                </nb-tab>

                <nb-tab tabTitle="Change Password">

                    <form [formGroup]="changePasswordForm">

                        <div class="row show-grid">

                            <!--Current Password form-group -->
                            <div class="col-12  col-sm-12  col-md-12">
                                <div class="form-group">
                                    <label for="currentPassword" class="label">Current Password</label>
                                    <input #currentPassword type="password" nbInput fullWidth id="currentPassword"
                                        placeholder="Current Password" formControlName="currentPassword"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': changePasswordSubmitted && changePasswordFormValidation.currentPassword.errors }">
                                    <div *ngIf="(changePasswordFormValidation['currentPassword'].touched && changePasswordFormValidation['currentPassword'].invalid) || changePasswordSubmitted"
                                        class="invalid-feedbackr">
                                        <div class="error"
                                            *ngIf="changePasswordFormValidation['currentPassword'].errors">
                                            Current Password Is Required.</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row show-grid">

                            <!--New Password form-group -->
                            <div class="col-12  col-sm-12  col-md-12">
                                <div class="form-group">
                                    <label for="newPassword" class="label">New Password</label>
                                    <input #newPassword type="password" nbInput fullWidth id="newPassword"
                                        placeholder="New Password" formControlName="newPassword" class="form-control"
                                        [ngClass]="{ 'is-invalid': changePasswordSubmitted && changePasswordFormValidation.newPassword.errors }">
                                    <div *ngIf="(changePasswordFormValidation['newPassword'].touched && changePasswordFormValidation['newPassword'].invalid) || changePasswordSubmitted"
                                        class="invalid-feedbackr">
                                        <div class="error"
                                            *ngIf="changePasswordFormValidation['newPassword'].errors ">
                                            New Password Is Required.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Confirm Password form-group -->
                        <div class="row show-grid">

                            <!--Confirm Password form-group -->
                            <div class="col-12  col-sm-12  col-md-12">
                                <div class="form-group">
                                    <label for="confirmPassword" class="label">Confirm Password</label>
                                    <input #confirmPassword type="password" nbInput fullWidth id="confirmPassword"
                                        placeholder="Confirm Password" formControlName="confirmPassword"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': changePasswordSubmitted && changePasswordFormValidation.confirmPassword.errors }">
                                    <div *ngIf="(changePasswordFormValidation['confirmPassword'].touched && changePasswordFormValidation['confirmPassword'].invalid) || changePasswordSubmitted"
                                        class="invalid-feedbackr">
                                        <div class="error"
                                            *ngIf="changePasswordFormValidation['confirmPassword'].errors">
                                            Confirm Password Is Required.</div>
                                    </div>
                                    <span class="error" *ngIf="changePasswordForm.get('confirmPassword').errors?.['mustMatch']"> The Confirm Password Does Not Match New Password</span>
                                </div>
                            </div>

                        </div>

                        <div class="row show-grid" style="border-top: 1px solid #151a30;padding-top: 10px; margin-top: 10px;">
                            <button type="submit" nbButton status="primary" size="medium" (click)="changePassword()">
                                <nb-icon icon="save"></nb-icon>
                            </button>
                        </div>
                    </form>
                </nb-tab>

            </nb-tabset>

        </nb-card-body>

    </nb-card>
</div>