import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { DialogModel } from '../../../models/dialog-model';

@Component({
  selector: 'ngx-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

 //#region Variable
 @Input() message: string;

 //#endregion

 constructor(private dialog: NbDialogRef<string>) { }

 ngOnInit(): void {
 
 }

 onSubmit() {


   
 }

 close(data){
   this.dialog.close(data);
 }
}
