import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NbDialogRef } from '@nebular/theme';
import { TosterService } from '../../../../../../../shared/services/toster.service';
import { BranchService } from '../../../../backOffice/branch/branch.service';
import { StoreService } from '../../../store/store.service';
import { AuthenticationService } from '../../../../../../../shared/services/authentication.service';
import { SelectionModel } from '../../../../../../../shared/models/selection-model';
import { StoreExpireService } from '../../services/store-expire.service';
import { environment } from '../../../../../../../environments/environment';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'ngx-inventory-item-expiry',
  templateUrl: './inventory-item-expiry.component.html',
  styleUrls: ['./inventory-item-expiry.component.scss']
})
export class InventoryItemExpiryComponent implements OnInit {

  //#region  Variable
  form: FormGroup;
  loading: boolean;
  submitted: boolean = false;
  @Input() dialogModel: any;
  dateTimeNow = new Date();
  invItemExpiryList = new Array<any>();
  storeList = new Array<SelectionModel>();
  branchList = new Array<SelectionModel>();
  //#endregion

  constructor(private router: Router, private tosterService: TosterService, private formBuilder: FormBuilder, private storeService: StoreService, private StoreExpireService: StoreExpireService,
    private dialog: NbDialogRef<any>, private branchService: BranchService, private datepipe: DatePipe, private authenticationService: AuthenticationService,) { }

  ngOnInit(): void {


    this.getSelections();
    this.form = this.formBuilder.group({
      storeId: new FormControl(null),
      branchId: new FormControl(null, [Validators.required]),
      toDate: new FormControl(new Date(), [Validators.required]),
    });
  }

  get formValidation(): { [key: string]: AbstractControl } { return this.form.controls; }


  getSelections() {
    this.branchService.getLookup().subscribe(
      res => {
        if (res.code == 302)
          this.branchList = res.data;
      },
      error => this.HandelingError(error)
    );
    this.storeService.getLookupByBranchId(0).subscribe(
      res => {
        debugger;
        if (res.code == 302)
          this.storeList = res.data;
      },
      error => this.HandelingError(error)
    );
  }

  GetStores(branchId) {
    this.formValidation.storeId.patchValue("null");
    if (branchId != null) {
      this.storeService.getLookupByBranchId(branchId).subscribe(
        res => {
          debugger;
          if (res.code == 302)
            this.storeList = res.data;
        },
        error => this.HandelingError(error)
      );
    } else {
      this.storeService.getLookupByBranchId(0).subscribe(
        res => {
          if (res.code == 302)
            this.storeList = res.data;
        },
        error => this.HandelingError(error)
      );

    }

  }

  HandelingError(error: string) {
    if (error.includes("401") || error.includes("500") || error.includes("0")) {
      this.tosterService.showToast("warning", "Your Session Has Expired Please Login In");
      setTimeout(() => {
        this.authenticationService.Logout();
        this.router.navigate(['/sign-in']);
      }, 3000);
    }
  }

  close(data) {
    this.dialog.close(data);
  }

  dateCalculator(value) {
    return new Date(value) < new Date() ? true : false
  }

  submit() {
    this.submitted = true;
    // if (this.form.valid) {
    this.loading = true;
    this.StoreExpireService.GetStoreExpireBySearchDTO(this.form.value).subscribe(
      res => {
        debugger
        if (res.code == 302)
          this.invItemExpiryList = res.data;
        this.loading = false;
      },
      error => this.HandelingError(error)
    );
    // }
  }



  printStoreExpire() {
    this.submitted = true;
    // if (this.form.valid) {

    this.StoreExpireService.GetStoreExpirePrint(this.form.value).subscribe(res => {
      this.loading = false;
      window.open(environment.reportPath + '/report/' + res.data, '_blank');
    },
      error => this.HandelingError(error)
    );




    // }
  }





}
