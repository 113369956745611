<div class="dialog-container"   [ngStyle]="{'width': (dialogModel == 'Inventory Item') ? '35vw' : '25vw'}">
    <nb-card [nbSpinner]="loading" nbSpinnerStatus="warning">

        <nb-card-header>
            <h6 style="float:left;color: #fc9b04">{{ cardHeader }}</h6>
            <button style="float:right;" nbButton status="danger" size="medium" (click)="close()">
                <nb-icon icon="close-outline"></nb-icon>
            </button>
        </nb-card-header>

        <nb-card-body>
            <form [formGroup]="form">

                <div class="row show-grid">

                    <div class="col-12  col-sm-6  col-md-6" style="margin-top:25px ;" *ngIf="dialogModel == 'Inventory Item'">
                        <div class="form-group">
                            <nb-radio-group status="warning" formControlName="basedOn" >
                                <nb-radio value="InvItem"  (valueChange)="clearFile()">
                                    Inv Item
                                </nb-radio>
                                <nb-radio value="Ingredient" (valueChange)="clearFile()">
                                    Ingredient
                                </nb-radio>
                            </nb-radio-group>
                        </div>
                    </div>

                    <!-- File form-group -->
                    <div   [ngClass]="{'col-12  col-sm-6  col-md-6': dialogModel == 'Inventory Item', 'col-12  col-sm-12  col-md-12': !(dialogModel == 'Inventory Item')}">
                        <div class="form-group">
                            <label for="file" class="label">File</label>
                            <div class="row show-grid" style=" margin-left:0px">
                                <label id="file" style=" margin-right:2rem">
                                    <i class="fa fa-upload fa-2x" aria-hidden="true"></i>
                                    <input type="file" style="display: none;"
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                        (change)="onChangeFile($event)" formControlName="file">
                                </label>
                                <label class="col-sm-7"
                                    *ngIf="(salesItemList.length>0 && dialogModel == 'Sales Item') || (recipeList.length>0 && dialogModel == 'Recipes') || (customerList.length>0 && dialogModel == 'Customer')  || ((inventoryItemList.length>0 || ingredietList.length>0) && dialogModel == 'Inventory Item')">{{fileName}}</label>
                            </div>
                            <div *ngIf="submitted && formValidation.file.errors" class="error">
                                Select File.
                            </div>
                        </div>
                    </div>

                </div>
            </form>
        </nb-card-body>

        <nb-card-footer>

            <button class="col-md-2" type="submit" nbButton status="primary" nbTooltip="Import Excel" size="medium"
                style="margin-right: 1rem ;" (click)="onSubmit()">
                <nb-icon icon="upload-outline"></nb-icon>
            </button>

            <button class="col-md-2" type="submit" nbButton status="success" nbTooltip="DownLoad Excel" size="medium"
                style="margin-right: 1rem ;" (click)="downloadExcel()">
                <nb-icon icon="download-outline"></nb-icon>
            </button>

            <button class="col-md-2" type="submit" nbButton status="danger" size="medium" (click)="clearFile()"
                nbTooltip="Delete Excel"
                *ngIf="(salesItemList.length>0 && dialogModel == 'Sales Item') || (customerList.length>0 && dialogModel == 'Customer') || ((inventoryItemList.length>0 || ingredietList.length>0) && dialogModel == 'Inventory Item')  || (recipeList.length>0 && dialogModel == 'Recipes')">
                <nb-icon icon="trash-2-outline"></nb-icon>
            </button>

        </nb-card-footer>

    </nb-card>

</div>