import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';
import { ResponseObject } from '../../../../../../shared/models/response-object';
import { SelectionModel } from '../../../../../../shared/models/selection-model';
import { InvRecipes } from '../models/inv-recipes';
import { RecipeSearchDTO } from '../models/recipeSearchDTO';

@Injectable({
  providedIn: 'root'
})
export class RecipesService {

  endPoint = environment.endPoint + 'InvMsRecipe/';
  endPointRecipePrint = environment.endPoint + 'RcipePrint/';
  httpHeader = { headers: new HttpHeaders() };

  constructor(private httpClient: HttpClient) { }

  setHeader() {
    if ((JSON.parse(localStorage.getItem('currentUser')!)) != null) {
      this.httpHeader = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'tenantId': (JSON.parse(localStorage.getItem('currentUser')!)).tenantId,
          'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('currentUser')!)).token
        })
      };
    }
  }

  add(obj: InvRecipes): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPoint + 'Create', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError))
  }
  

  importXLS(obj:any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPoint + 'ImportExcel', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError))
  }



  update(obj: InvRecipes): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.put<ResponseObject>(this.endPoint + 'Update', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError))
  }

  getData(obj: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPoint + 'GetRecipeBySearchDTO', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  getRecipeTransaction(branchId: number, salesItemId: string): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + 'GetRecipeTransaction?branchId=' + branchId + '&salesItemId=' + salesItemId, this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  get(branchId: number,priceModeId:number, salesItemId: number): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + 'Get?branchId=' + branchId +'&priceModeId='+priceModeId+ '&salesItemId=' + salesItemId, this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }


  getRecipePrint(obj: InvRecipes): Observable<ResponseObject> {
    this.setHeader();

    return this.httpClient.post<ResponseObject>(this.endPointRecipePrint + 'GetRecipePrint', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }





  GetRecipeWithOutCoastPrint(obj: InvRecipes): Observable<ResponseObject> {
    this.setHeader();

    return this.httpClient.post<ResponseObject>(this.endPointRecipePrint + 'GetRecipeWithOutCoastPrint', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }











  processError(err: any) {
    let message = '';
    if (err.error instanceof ErrorEvent) {
      message = err.error.message;
    } else {
      message = `Error Code: ${err.status}\nMessage: ${err.message}`;
    }
    return throwError(message);
  }
}
