<div class="dialog-container">
    <nb-card  [nbSpinner]="loading" nbSpinnerStatus="warning" nbSpinnerSize="giant">

        <nb-card-header>
            <h6 style="float:left;color: #fc9b04">Inventory Item Expiry</h6>
            <button style="float:right;" nbButton status="danger" size="medium" (click)="close(null)">
                <nb-icon icon="close-outline"></nb-icon>
            </button>
        </nb-card-header>

        <nb-card-body>
            <form [formGroup]="form" >
                <div class="row show-grid">

                    <!-- Branch form-group -->
                    <div class="col-12  col-sm-3  col-md-3">
                        <label for="branchId" class="label">Branch</label>
                        <nb-select formControlName="branchId" id="branchId" placeholder="Branch" fullWidth
                            id="drpBranch" (selectedChange)="GetStores($event)">
                            <nb-option [value]="null">
                                <nb-icon status="danger" icon="trash-2-outline" style="margin-left:90%;"></nb-icon>
                            </nb-option>
                            <nb-option *ngFor="let branch of branchList" [value]="branch.id">
                                {{branch.name}}</nb-option>
                        </nb-select>
                    </div>

                    <!-- Store form-group -->
                    <div class="col-12  col-sm-3  col-md-3">
                        <label for="storeId" class="label">Store</label>
                        <nb-select formControlName="storeId" id="storeId" placeholder="Store" fullWidth>
                            <nb-option [value]="null">
                                <nb-icon status="danger" icon="trash-2-outline" style="margin-left:90%;"></nb-icon>
                            </nb-option>
                            <nb-option *ngFor="let store of storeList" [value]="store.id">
                                {{store.name}}</nb-option>
                        </nb-select>

                    </div>

                    <!-- To Date form-group -->
                    <div class="col-12  col-sm-3 col-md-3">
                        <div class="form-group">
                            <label class="label">To Date</label>
                            <input nbInput placeholder="To Date" formControlName="toDate"
                                [nbDatepicker]="toDateTimePicker" fullWidth>
                            <nb-datepicker #toDateTimePicker></nb-datepicker>
                            <div *ngIf="submitted && formValidation.toDate.errors" class="error">
                                To Date is Required.
                            </div>
                        </div>
                    </div>

                    <div class="col-12  col-sm-2 col-md-2" style="text-align:right;margin-top:1.5em;">

                        <button type="submit" nbButton status="primary" size="medium" style="margin-right:1.5em;"
                            nbTooltip="View" (click)="submit()">
                            <nb-icon icon="search-outline"></nb-icon>
                        </button>

                        <button type="submit" nbButton status="success" size="medium" nbTooltip="Print"
                            (click)="printStoreExpire()">
                            <nb-icon icon="printer-outline"></nb-icon>
                        </button>

                    </div>
                </div>

                <div class="row show-grid" style="margin-top: 1.5em;">
                    <table nbtable class="table table-striped table-bordered table-hover table-sm table-responsive">
                        <thead>
                            <tr style="background-color: #d19934;">
                                <th width="20%">Branch</th>
                                <th width="20%">Store</th>
                                <th width="25%">Item</th>
                                <th width="10%">Qty</th>
                                <th width="10%">Unit</th>
                                <th width="12%">Expiry Date</th>
                            </tr>
                        </thead>

                        <!-- invItemExpiryList -->
                        <tbody>
                            <tr *ngFor="let invItemExpiry of invItemExpiryList">
                                <td width="20%"
                                    [ngStyle]="{'color':dateCalculator(invItemExpiry.date) ?  '#ff3d71' :'#fffff' }">
                                    {{invItemExpiry.branch}}
                                </td>
                                <td width="20%"
                                    [ngStyle]="{'color':dateCalculator(invItemExpiry.date) ?  '#ff3d71' :'#fffff' }">
                                    {{invItemExpiry.store}}
                                </td>
                                <td width="25%"
                                    [ngStyle]="{'color':dateCalculator(invItemExpiry.date) ?  '#ff3d71' :'#fffff' }">
                                    {{invItemExpiry.item}}
                                </td>
                                <td width="10%"
                                    [ngStyle]="{'color':dateCalculator(invItemExpiry.date) ?  '#ff3d71' :'#fffff' }">

                                    {{invItemExpiry.qty}}
                                </td>
                                <td width="10%"
                                    [ngStyle]="{'color':dateCalculator(invItemExpiry.date) ?  '#ff3d71' :'#fffff' }">

                                    {{invItemExpiry.storageUnit}}
                                </td>
                                <td width="12%"
                                    [ngStyle]="{'color':dateCalculator(invItemExpiry.date) ?  '#ff3d71' :'#fffff' }">

                                    {{invItemExpiry.date | date:"dd/MM/yyyy" }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </form>
        </nb-card-body>

    </nb-card>

</div>