
import { Injectable } from '@angular/core';
import { Observable ,throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { ResponseObject } from '../../../../../shared/models/response-object';
import { Branch } from './models/branch';
import { AuthenticationService } from '../../../../../shared/services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class BranchService {

  endPoint = environment.endPoint + 'Branch/';
  httpHeader = { headers: new HttpHeaders()};


  constructor(private httpClient: HttpClient , private authenticationService:AuthenticationService) { }

  getAll(): Observable<ResponseObject> {
    this.httpHeader = {
      headers: new HttpHeaders({
       'Content-Type': 'application/json',
       'tenantId':this.authenticationService.currentUserValue.tenantId,
       'Authorization' : 'Bearer ' + this.authenticationService.currentUserValue.token
      })};
    return this.httpClient.get<ResponseObject>(this.endPoint + 'GetAll', this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  getById(id: any): Observable<ResponseObject> {
    this.httpHeader = {
      headers: new HttpHeaders({
       'Content-Type': 'application/json',
       'tenantId':this.authenticationService.currentUserValue.tenantId,
       'Authorization' : 'Bearer ' + this.authenticationService.currentUserValue.token
      })};
    return this.httpClient.get<ResponseObject>(this.endPoint + 'GeById/' + id, this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  getLookup(): Observable<ResponseObject> {
    this.httpHeader = {
      headers: new HttpHeaders({
       'Content-Type': 'application/json',
       'tenantId':this.authenticationService.currentUserValue.tenantId,
       'Authorization' : 'Bearer ' + this.authenticationService.currentUserValue.token
      })};
    return this.httpClient.get<ResponseObject>(this.endPoint + 'GetLookup',this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  getLookupByComapny( ids :Array<number>): Observable<ResponseObject> {
    this.httpHeader = {
      headers: new HttpHeaders({
       'Content-Type': 'application/json',
       'tenantId':this.authenticationService.currentUserValue.tenantId,
       'Authorization' : 'Bearer ' + this.authenticationService.currentUserValue.token
      })};
    return this.httpClient.post<ResponseObject>(this.endPoint + 'GetLookupByComapny' , JSON.stringify(ids) ,this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  add(obj: Branch): Observable<ResponseObject> {
    this.httpHeader = {
      headers: new HttpHeaders({
       'Content-Type': 'application/json',
       'tenantId':this.authenticationService.currentUserValue.tenantId,
       'Authorization' : 'Bearer ' + this.authenticationService.currentUserValue.token
      })};
    return this.httpClient.post<ResponseObject>(this.endPoint + 'Create', JSON.stringify(obj),this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError))
  }

  update(obj: Branch): Observable<ResponseObject> {
    this.httpHeader = {
      headers: new HttpHeaders({
       'Content-Type': 'application/json',
       'tenantId':this.authenticationService.currentUserValue.tenantId,
       'Authorization' : 'Bearer ' + this.authenticationService.currentUserValue.token
      })};
    return this.httpClient.put<ResponseObject>(this.endPoint + 'Update', JSON.stringify(obj),this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  delete(obj: Branch): Observable<ResponseObject> {
    this.httpHeader = {
      headers: new HttpHeaders({
       'Content-Type': 'application/json',
       'tenantId':this.authenticationService.currentUserValue.tenantId,
       'Authorization' : 'Bearer ' + this.authenticationService.currentUserValue.token
      })};
    return this.httpClient.post<ResponseObject>(this.endPoint + 'Delete', JSON.stringify(obj),this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  processError(err: any) {
    let message = '';
    if (err.error instanceof ErrorEvent) {
      message = err.error.message;
    } else {
      message = `Error Code: ${err.status}\nMessage: ${err.message}`;
    }

    return throwError(message);
  }

}
