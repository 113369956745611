import { HttpHeaders } from "@angular/common/http";
import { WebUser } from "../../../../../app/pages/modules/backOffice/webUser/models/web-user";


export class Signin {

    tenantId : string ;
    loggedin :boolean;
    // authUserDTO :AuthUserDTO;
    webUser : WebUser;
    token : string;
    invExpanderConfigs:[];
    invBranchStoreConfigs:[];
    invNotificationCountsDTO:any;

    httpHeader = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'tenantId':'',
          'Authorization':'Bearer '  
        })
      }


}
