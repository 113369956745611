import { CustomerZoneAddress } from "./customer-zone-address";

export class Customer {

    id: string;
    branchId: number;
    fname: string;
    lname: string;
    titleId: number;
    genderId: number;
    companyName: string;
    email: string;
    mobile1: string;
    mobile2: string;
    phone1: string;
    phone2: string;
    fax: string;
    customerGroupId: number;
    nationality: string;
    code: string;
    membershipCode: string;
    remarks: string;
    blackList: boolean;
    birthdate?: Date;
    priceModeId: number;
    picture: string;
    cardCreationDate: Date;
    cardExpireDate: Date;
    disContinued: boolean;
    discountFlag: boolean;
    discountId: number;
    accNumber: string;
    creditLimit: number;
    prepaidTicket: string;
    active: boolean;
    isCloud :boolean;
    customerZoneAddresses = new Array<CustomerZoneAddress>();
}
