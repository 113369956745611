import { Injectable } from '@angular/core';
import { NbComponentStatus, NbGlobalPhysicalPosition, NbToastrService } from '@nebular/theme';

@Injectable({
  providedIn: 'root'
})
export class TosterService {

  constructor(private toastrService : NbToastrService) { }
  
  showToast(status: NbComponentStatus ,message :string) {
    const config = {
      duration : 3500,
      status: status,
      position: NbGlobalPhysicalPosition.BOTTOM_RIGHT,
    };
    this.toastrService.show( '' , message, config  );
  }
}
