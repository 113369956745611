import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { NbAdjustableConnectedPositionStrategy, NbDialogRef, NbDialogService } from '@nebular/theme';
import { SalesItemService } from '../../../app/pages/modules/backOffice/salesItem/services/sales-item.service';
import { AuthenticationService } from '../../services/authentication.service';
import { TosterService } from '../../services/toster.service';
import { SalesItem } from '../../../app/pages/modules/backOffice/salesItem/models/sales-item';
import { Modifier } from '../../../app/pages/modules/backOffice/salesItem/models/modifier';
import { BranchItemPrices } from '../../../app/pages/modules/backOffice/salesItem/models/branch-item-prices';
import { BranchService } from '../../../app/pages/modules/backOffice/branch/branch.service';
import { SalesItemImport } from '../../../app/pages/modules/backOffice/salesItem/models/sales-item-import';
import { Console, debug } from 'console';
import * as uuid from 'uuid';
import { Customer } from '../../../app/pages/modules/backOffice/customer/models/customer';
import { CustomerZoneAddress } from '../../../app/pages/modules/backOffice/customer/models/customer-zone-address';
import { CustomerService } from '../../../app/pages/modules/backOffice/customer/services/customer.service';
import { ConfirmationComponent } from '../confirmation/confirmation/confirmation.component';
import { InvItem } from '../../../app/pages/modules/inventory-management/inventoryItem/models/inv-item';
import { ItemService } from '../../../app/pages/modules/inventory-management/inventoryItem/services/item.service';
import { InvMaxMin } from '../../../app/pages/modules/inventory-management/inventoryItem/models/inv-max-min';
import { InvItemTag } from '../../../app/pages/modules/inventory-management/tag/models/inv-item-tag';
import { ItemImportDTO } from '../../../app/pages/modules/inventory-management/inventoryItem/models/item-import-dto';
import { InvRecipes } from '../../../app/pages/modules/inventory-management/Recipes/models/inv-recipes';
import { InvRecipesDetails } from '../../../app/pages/modules/inventory-management/Recipes/models/inv-recipes-details';
import { ExcelRecipe } from '../../../app/pages/modules/inventory-management/Recipes/models/excel-recipe';
import { ExcelRecipeDetail } from '../../../app/pages/modules/inventory-management/Recipes/models/excel-recipe-detail';
import { RecipesService } from '../../../app/pages/modules/inventory-management/Recipes/services/recipes.service';
import { WarningComponent } from '../warning/warning.component';
import { PriceModeService } from '../../../app/pages/modules/backOffice/salesItem/services/price-mode.service';
import { SelectionModel } from '../../models/selection-model';
import { isNumeric } from 'rxjs/internal-compatibility';
import { ExcelIngredient } from '../../../app/pages/modules/inventory-management/inventoryItem/models/excel-ingredient';
import { ExcelIngredientDetail } from '../../../app/pages/modules/inventory-management/inventoryItem/models/excel-ingredient-detail';

@Component({
  selector: 'ngx-import-excel',
  templateUrl: './import-excel.component.html',
  styleUrls: ['./import-excel.component.scss']
})
export class ImportExcelComponent implements OnInit {

  //#region Variable

  form: FormGroup;
  fileName: string;
  submitted = false;
  cardHeader: String;
  branches: Array<any>;
  loading: boolean = false;
  @Input() dialogModel: string;
  recipeList = new Array<ExcelRecipe>();
  customerList = new Array<Customer>();
  branchList = new Array<SelectionModel>();
  ingredietList = new Array<ExcelIngredient>();
  priceModeList = new Array<SelectionModel>();
  salesItemList = new Array<SalesItemImport>();
  inventoryItemList = new Array<ItemImportDTO>();



  //#endregion

  constructor(private dialog: NbDialogRef<any>, private authenticationService: AuthenticationService, private router: Router, private customerService: CustomerService, private itemService: ItemService,
    private salesItemService: SalesItemService, private priceModeService: PriceModeService, private tosterService: TosterService, private builder: FormBuilder, private branchService: BranchService, private dialogService: NbDialogService, private recipesService: RecipesService) { }

  ngOnInit(): void {
    this.branchService.getLookup().subscribe(data => { this.branches = data.data });
    this.setCardHeader(this.dialogModel);
    this.form = this.builder.group(
      {
        basedOn: new FormControl("InvItem", []),
        file: new FormControl("", [Validators.required])

      });
    if (this.dialogModel == "Recipes")
      this.getSelections();
  }

  setCardHeader(dialogModel: string) { this.cardHeader = "Import " + dialogModel; }

  get formValidation(): { [key: string]: AbstractControl } { return this.form.controls; }

  getSelections() {

    this.branchService.getLookup().subscribe(
      res => {
        if (res.code == 302)
          this.branchList = res.data;
      },
      error => this.HandelingError(error)
    );


    this.priceModeService.getLookup().subscribe(
      res => {
        if (res.code == 302)
          this.priceModeList = res.data.filter(item => item.id > 0);
      },
      error => this.HandelingError(error)
    );
  }

  onChangeFile(event) {

    if (this.checkOnExtention(event.target.files[0])) {
      this.fileName = event.target.files[0].name;
      const target: DataTransfer = <DataTransfer>(event.target);
      if (target.files.length !== 1) { this.tosterService.showToast('warning', 'Cannot use multiple files'); }
      const reader: FileReader = new FileReader();
      reader.readAsBinaryString(target.files[0]);
      reader.onload = (e: any) => {
        /* create workbook */
        const binarystr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
        /* selected the first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        /* save data */
        const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
        if (this.dialogModel == "Sales Item")
          this.mapRawdataToSalesItems(data);
        else if (this.dialogModel == "Customer")
          this.mapRawdataToCustomers(data);
        else if (this.dialogModel == "Recipes")
          this.mapRawdataToRecipes(data);
        else if (this.dialogModel == "Inventory Item" && this.formValidation.basedOn.value == "InvItem")
          this.mapRawdataToInventoryItem(data);
        else if (this.dialogModel == "Inventory Item" && this.formValidation.basedOn.value == "Ingredient")
          this.mapRawdataToIngredient(data);
      };

    }
  }

  checkOnExtention(file) {
    var fileExt = file.name.substring(file.name.lastIndexOf('.'));
    if (fileExt != ".xlsx") {
      this.tosterService.showToast('warning', "Invalid file , Please use template"); return false;
    } else return true;
  }

  close() { this.dialog.close(); }

  onSubmit() {
    this.dialogService.open(ConfirmationComponent,
      {
        context: { message: "Are you sure you want to submit the data import?" },
        closeOnBackdropClick: false,
        autoFocus: true,
        dialogClass: 'model-full'
      })
      .onClose.subscribe(rep => {
        if (rep) {

          this.submitted = true
          if (this.form.valid) {
            if (this.dialogModel == "Sales Item") {
              if (this.salesItemList.length > 0) {
                if (this.findSalesItemDuplicates() == false) this.importSalesItemXLS();
              }
            } else if (this.dialogModel == "Customer") {
              if (this.customerList.length > 0) {
                if (this.findCustomerDuplicates() == false) this.importCustomerXLS();
              }
            }
            else if (this.dialogModel == "Inventory Item" && this.formValidation.basedOn.value == "InvItem") {
              if (this.inventoryItemList.length > 0) {
                if (this.findInventoryItemDuplicates() == false) this.importInventoryItemXLS();
              }
            }
            else if (this.dialogModel == "Inventory Item" && this.formValidation.basedOn.value == "Ingredient") {
              if (this.ingredietList.length > 0)
                this.importIngredientXLS();
            }
            else if (this.dialogModel == "Recipes") {
              if (this.recipeList.length > 0) {
                this.importRecipeXLS();
              }

            }
          }
        }
      });
  }

  clearFile() {
    this.fileName = null;
    this.customerList = new Array<Customer>();
    this.formValidation.file.patchValue(null);
    this.formValidation.file.updateValueAndValidity();
    this.salesItemList = new Array<SalesItemImport>();
    this.ingredietList = new Array<ExcelIngredient>();
    this.inventoryItemList = new Array<InvItem>();
  }

  HandelingError(error: string) {
    if (error.includes("401") || error.includes("500") || error.includes("0")) {
      this.tosterService.showToast("warning", "Your Session Has Expired Please Login In");
      setTimeout(() => {
        this.authenticationService.Logout();
        this.router.navigate(['/sign-in']);
      }, 3000);
    }
  }


  //#region  Customer

  mapRawdataToCustomers(obj: any[]) {
    this.customerList = new Array<Customer>();
    obj.forEach(element => {
      let customer = new Customer();
      customer.id = uuid.v4();
      customer.active = true;
      customer.branchId = 0;
      customer.titleId = 1;
      customer.genderId = 1;
      customer.creditLimit = null;
      customer.priceModeId = null;
      customer.picture = null;
      customer.accNumber = null;
      customer.blackList = false;
      customer.birthdate = null;
      customer.discountId = null;
      customer.fax = element.Fax;
      customer.code = element.Code;
      customer.prepaidTicket = null;
      customer.cardExpireDate = null;
      customer.disContinued = null;
      customer.discountFlag = false;
      customer.fname = element.Fname;
      customer.lname = element.Lname;
      customer.email = element.Email;
      customer.cardCreationDate = null;
      customer.phone1 = element.Phone1;
      customer.phone2 = element.Phone2;
      customer.mobile1 = element.Mobile1;
      customer.mobile2 = element.Mobile2;
      customer.remarks = element.Remarks;
      customer.nationality = element.Nationality;
      customer.companyName = element.CompanyName;
      customer.membershipCode = element.MembershipCode;
      customer.customerGroupId = element.CustomerGroupID;
      if (element.ZoneId > 0) {
        var customerZoneAddresses: Array<CustomerZoneAddress> = [{
          id: uuid.v4(),
          customerId: customer.id,
          state: element.State,
          street: element.Street,
          building: element.Building,
          floor: element.Floor,
          nearby: element.Nearby,
          zipCode: element.ZipCode,
          zoneId: element.ZoneId,
          zoneName: '',
          appartment: element.Appartment,
          comment: element.Comment
        }];
        customer.customerZoneAddresses = customerZoneAddresses;
      }
      this.customerList.push(customer);

    });
  }

  findCustomerDuplicates(): boolean {

    var res = false;
    this.customerList.forEach((item) => {
      if (this.customerList.filter(i => i.mobile1 === item.mobile1).length > 1) { res = true; this.tosterService.showToast('warning', "Mobile Duplicates " + item.mobile1); return res; }
    });
    return res;
  }

  importCustomerXLS() {
    this.loading = true;
    this.customerService.importXLS(this.customerList).subscribe(res => {
      if (res.code == 201) {
        this.tosterService.showToast('success', res.message);
        this.close();
      } else if (res.code == 400) {
        this.tosterService.showToast('warning', res.message);
      }
      this.loading = false;
    }, error => this.HandelingError(error));
  }

  //#endregion


  //#region  SalesItem

  mapRawdataToSalesItems(obj: any[]) {
    this.salesItemList = new Array<SalesItemImport>();
    obj.forEach(element => {
      let salesItem = new SalesItemImport();
      salesItem.avilable = true;
      salesItem.code = element.Code;
      salesItem.groupId = element.GroupID;
      salesItem.barcode1 = element.Barcode1;
      salesItem.barcode2 = element.Barcode2;
      salesItem.barcode3 = element.Barcode3;
      salesItem.describtion = element.Description;
      salesItem.itemFunctionId = element.FunctionID;
      salesItem.menuDiscribtion = element.MenuDescription;
      salesItem.kichenDiscribtion = element.KichenDescription;
      this.branches.forEach(brench => {
        salesItem.branchItemPrices.push({ id: 0, branchId: brench.id, salesItemId: 0, priceModeId: 1, value: element.Value1 });
        salesItem.branchItemPrices.push({ id: 0, branchId: brench.id, salesItemId: 0, priceModeId: 2, value: element.Value2 });
        salesItem.branchItemPrices.push({ id: 0, branchId: brench.id, salesItemId: 0, priceModeId: 3, value: element.Value3 });
        salesItem.branchItemPrices.push({ id: 0, branchId: brench.id, salesItemId: 0, priceModeId: 4, value: element.Value4 });
      });
      this.salesItemList.push(salesItem);
    });
  }

  findSalesItemDuplicates(): boolean {
    var res = false;
    this.salesItemList.forEach((item) => {
      if (this.salesItemList.filter(i => i.describtion === item.describtion).length > 1) { res = true; this.tosterService.showToast('warning', "Description Duplicates " + item.describtion); return res; }
    });
    return res;
  }

  importSalesItemXLS() {
    this.loading = true;
    this.salesItemService.importXLS(this.salesItemList).subscribe(res => {
      if (res.code == 201) {
        this.tosterService.showToast('success', res.message);
        this.close();
      } else if (res.code == 400) {
        this.tosterService.showToast('warning', res.message);
      }
      this.loading = false;
    }, error => this.HandelingError(error));
  }

  //#endregion


  //#region InventoryItem


  mapRawdataToInventoryItem(obj: any[]) {
    this.inventoryItemList = new Array<ItemImportDTO>();
    obj.forEach(element => {
      let invItem = new InvItem();
      invItem.id = 0;
      invItem.branchId = null;
      invItem.supplierId = element.SupplierID;
      invItem.storeId = element.StoreID;
      invItem.description = element.Description;
      invItem.otherDescription = element.OtherDescription;
      invItem.sku = element.SKU;
      invItem.barcode1 = element.Barcode1;
      invItem.barcode2 = element.Barcode2;
      invItem.groupId = element.GroupID;
      invItem.PurchasingUnitId = element.PurchasingUnitID;
      invItem.storageUnitId = element.StorageUnitID;
      invItem.sellingUnitId = element.SellingUnitID;
      invItem.productionUnitId = element.ProductionUnitID;
      invItem.purchaseInStorage = element.PurchaseRatio;
      invItem.storageInSelling = element.StorageRatio;
      invItem.productionQty = element.ProductionQty;
      invItem.unitCost = element.UnitCost;
      invItem.avgCost = element.AvgCost;
      invItem.sp1 = element.SP1;
      invItem.sp2 = element.SP2;
      invItem.sp3 = element.SP3;
      invItem.sp4 = element.SP4;





      invItem.dailyCount = null;
      invItem.weeklyCount = null;
      invItem.asService = null;
      invItem.expiryDate = null;
      invItem.discountinued = null;
      invItem.hideInReport = null;
      invItem.option1 = null;
      invItem.option2 = null;
      invItem.option3 = null;
      invItem.caloriesUnitId = null;
      invItem.caloriesValue = null;
      invItem.picture = null;
      invItem.qtyToPrepare = null;
      invItem.cookingNote = null;
      invItem.cookingPicture = null;
      invItem.caloriesUnitId = null;
      invItem.caloriesValue = null;
      this.inventoryItemList.push(invItem);

    });


  }


  findInventoryItemDuplicates(): boolean {
    var res = false;
    this.inventoryItemList.forEach((item) => {

      console.log(item);

      if (this.inventoryItemList.filter(i => i.description === item.description).length > 1) { res = true; this.tosterService.showToast('warning', "Description Duplicates " + item.description); return res; }
      else if (this.inventoryItemList.filter(i => i.sku === item.sku).length > 1) { res = true; this.tosterService.showToast('warning', "SKU Duplicates " + item.sku); return res; }
      else if (this.inventoryItemList.filter(i => i.barcode1 === item.barcode1 && i.barcode1 != null).length > 1) { res = true; this.tosterService.showToast('warning', "barcode1 Duplicates " + item.barcode1); return res; }
      else if (this.inventoryItemList.filter(i => i.barcode2 === item.barcode2 && i.barcode2 != null).length > 1) { res = true; this.tosterService.showToast('warning', "barcode2 Duplicates " + item.barcode2); return res; }


    });
    return res;
  }


  importInventoryItemXLS() {
    this.loading = true;
    this.itemService.importXLS(this.inventoryItemList).subscribe(res => {
      if (res.code == 201) {
        this.tosterService.showToast('success', res.message);
        this.close();
      } else if (res.code == 400) {
        this.tosterService.showToast('warning', res.message);
      }
      this.loading = false;
    }, error => this.HandelingError(error));
  }



  //#endregion


  //#region Ingredients

  mapRawdataToIngredient(obj: any[]) {
    this.ingredietList = new Array<ExcelIngredient>();
    if (obj.length > 0)
      var isvalid = true;
    obj.forEach(element => {
      if (isvalid) {
        if (isNumeric(element.QtyToPrepare) && Number(element.QtyToPrepare) > 0) {
          if (this.ingredietList.find(l => l.description == element.MainItem) == undefined) {
            let header = new ExcelIngredient();
            header.description = element.MainItem;
            header.qtyToPrepare = element.QtyToPrepare;
            var objDetails = obj.filter(l => l.MainItem == element.MainItem);
            objDetails.forEach(item => {
              if (isvalid) {
                if (item.DetailItem != element.MainItem) {
                  if (isNumeric(item.QTY) && Number(item.QTY) > 0) {
                    var newItem = new ExcelIngredientDetail();
                    newItem.qty = item.QTY;
                    newItem.description = item.DetailItem;
                    header.excelIngredientDetailDTOs.push(newItem);
                  } else {
                    this.callWarnningMes(item.DetailItem + " Must Have Value More Than 0 !");
                    this.clearFile(); isvalid = false; return;
                  }
                } else {
                  this.callWarnningMes(item.DetailItem + " Is a MainItem And Detail Item In Same Time");
                  this.clearFile(); isvalid = false; return;
                }
              }
            });
            this.ingredietList.push(header);
          }
        }
        else {
          this.callWarnningMes(element.MainItem + " Must Have Value More Than 0 !");
          this.clearFile(); isvalid = false; return;
        }
      }
    });
    console.log(this.ingredietList)
  }

  importIngredientXLS() {
    this.loading = true;
    this.itemService.importIngredientXLS(this.ingredietList).subscribe(res => {
      if (res.code == 201) {
        this.tosterService.showToast('success', res.message);
        this.close();
      } else if (res.code == 400) {
       this.callWarnningMes(res.message);
        this.clearFile();
      }
      this.loading = false;
    }, error => this.HandelingError(error));
  }

  callWarnningMes(mes) {
    this.dialogService.open(WarningComponent,
      {
        context: { message: mes },
        closeOnBackdropClick: false, autoFocus: true, dialogClass: 'model-full'
      });
  }

  //#endregion


  //#region Recipes
  mapRawdataToRecipes(obj: any[]) {
    this.recipeList = new Array<ExcelRecipe>();
    if (obj.length > 0)
      var qtyValidation = true;
    obj.forEach(element => {
      if (qtyValidation) {
        if (this.recipeList.find(l => l.description == element.MainItem) == undefined) {
          this.branchList.forEach(branch => {
            this.priceModeList.forEach(mode => {
              let header = new ExcelRecipe();
              header.description = element.MainItem;
              header.branchId = branch.id;
              header.priceModeId = mode.id;
              var objDetails = obj.filter(l => l.MainItem == element.MainItem);
              objDetails.forEach(item => {
                if (qtyValidation) {
                  if (isNumeric(item.QTY) && Number(item.QTY) > 0) {
                    var newItem = new ExcelRecipeDetail();
                    newItem.qty = item.QTY;
                    newItem.description = item.DetailItem;
                    header.excelRecipeDetailDTOs.push(newItem);
                  } else {
                    this.dialogService.open(WarningComponent,
                      {
                        context: { message: item.DetailItem + " Must Have Value More Than 0 !" },
                        closeOnBackdropClick: false, autoFocus: true, dialogClass: 'model-full'
                      });
                    this.clearFile();
                    qtyValidation = false;
                    return;
                  }
                }
              });
              this.recipeList.push(header);
            });
          });
        }
      }

    });
  }


  importRecipeXLS() {
    this.loading = true;
    this.recipesService.importXLS(this.recipeList).subscribe(res => {
      if (res.code == 201) {
        this.tosterService.showToast('success', res.message);
        this.close();
      } else if (res.code == 400) {
        this.dialogService.open(WarningComponent,
          {
            context: { message: res.message },
            closeOnBackdropClick: false, autoFocus: true, dialogClass: 'model-full'
          })
        this.clearFile();
      }
      this.loading = false;
    }, error => this.HandelingError(error));
  }
  //#endregion


  //#region  DownloadExcel

  downloadExcel() {
    if (this.dialogModel == "Sales Item")
      this.downloadSalesItemExcel();
    else if (this.dialogModel == "Customer")
      this.downloadCustomerExcel();

    else if (this.dialogModel == "Recipes")
      this.downloadRecipesExcel();

    else if (this.dialogModel == "Inventory Item" && this.formValidation.basedOn.value == "InvItem")
      this.downloadInventoryItemExcel();
    else if (this.dialogModel == "Inventory Item" && this.formValidation.basedOn.value == "Ingredient")
      this.downloadIngredientExcel();
  }

  downloadSalesItemExcel() {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet
      ([{ FunctionID: 1, GroupID: 1, Code: 1, Description: "CEVICHE DE PESCADO", MenuDescription: "CEVICHE DE PESCADO", KichenDescription: "CEVICHE DE PESCADO", Barcode1: 0, Barcode2: 0, Barcode3: 0, Value1: 1.3, Value2: 1.2, Value3: 1.4, Value4: 1.2 }]);
    worksheet["!cols"] = [{ wch: 17 }];

    XLSX.utils.sheet_add_aoa(workbook, [["FunctionID", "GroupID", "Code", "Description", "MenuDescription", "KichenDescription", "Barcode1", "Barcode2", "Barcode3", "Value1", "Value2", "Value3", "Value4"]], { origin: "A1" });
    XLSX.utils.book_append_sheet(workbook, worksheet, 'SalesItemSample.xlsx');
    /* save to file */
    XLSX.writeFile(workbook, 'SalesItemSample.xlsx');
  }

  downloadCustomerExcel() {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet
      ([{
        Fname: 'Eman', Lname: 'Sabry', CompanyName: '', Email: '', Mobile1: "01010090900", Mobile2: '', Phone1: '', Phone2: '', Fax: '', CustomerGroupID: 1, Nationality
          : '', Code: '', MembershipCode: '', Remarks: '', Birthdate: '', State: '', Street: '', Building: '', Floor: '', Nearby: '', ZipCode: ''
      }]);
    XLSX.utils.sheet_add_aoa(workbook, [["Fname", "Lname", "CompanyName", "Email", "Mobile1", "Mobile2", "Phone1", "Phone2", "Fax", "CustomerGroupID", "Nationality", "Code",
      "MembershipCode", "Remarks", "Birthdate", "State", "Street", "Building", "Floor", "Nearby", "ZipCode"]], { origin: "A1" });
    XLSX.utils.book_append_sheet(workbook, worksheet, 'CustomerSample.xlsx');
    /* save to file */
    XLSX.writeFile(workbook, 'CustomerSample.xlsx');
  }

  downloadRecipesExcel() {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet
      ([{
        MainItem: 'Pizza', DetailItem: 'Tomato', QTY: '10'
      }]);
    XLSX.utils.sheet_add_aoa(workbook, [["MainItem", "DetailItem", "QTY"]], { origin: "A1" });
    XLSX.utils.book_append_sheet(workbook, worksheet, 'RecipeSample.xlsx');
    /* save to file */
    XLSX.writeFile(workbook, 'RecipeSample.xlsx');
  }

  downloadIngredientExcel() {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet
      ([{
        MainItem: 'Apple Jouse', QtyToPrepare: 10, DetailItem: 'Appel', QTY: '10'
      }]);
    XLSX.utils.sheet_add_aoa(workbook, [["MainItem", "QtyToPrepare", "DetailItem", "QTY"]], { origin: "A1" });
    XLSX.utils.book_append_sheet(workbook, worksheet, 'IngredientSample.xlsx');
    /* save to file */
    XLSX.writeFile(workbook, 'IngredientSample.xlsx');

  }

  downloadInventoryItemExcel() {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet
      ([{ SupplierID: 1, StoreID: 1, Description: "Description", OtherDescription: "OtherDescription", SKU: "SKU", Barcode1: "11", Barcode2: "22", GroupID: 1, PurchasingUnitID: 1, PurchaseRatio: 1, StorageUnitID: 1, StorageRatio: 1, SellingUnitID: 1, ProductionUnitID: 1, ProductionQty: 1, UnitCost: 1, AvgCost: 1, SP1: 1, SP2: 1, SP3: 1, SP4: 1 }]);
    worksheet["!cols"] = [{ wch: 17 }];

    XLSX.utils.sheet_add_aoa(workbook, [["SupplierID", "StoreID", "Description", "", "OtherDescription", "SKU", "Barcode1", "Barcode2", "GroupID", "PurchasingUnitID", "PurchaseRatio", "StorageUnitID", "StorageRatio", "SellingUnitID", "ProductionUnitID", "ProductionQty", "UnitCost", "AvgCost", "SP1", "SP2", "SP3", "SP4"]], { origin: "A1" });
    XLSX.utils.book_append_sheet(workbook, worksheet, 'InventoryItemSample.xlsx');
    /* save to file */
    XLSX.writeFile(workbook, 'InventoryItemSample.xlsx');
  }



  //#endregion



}
