import { Component } from '@angular/core';
import { AuthenticationService } from '../../../../shared/services/authentication.service';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">Company ID : {{companyId}}</span>
<div class="content"> 
    <span class="created-by">
     © 2021-2024 Aqua POS. all rights reserved.
    </span>
    <span class="created-by">
      www.aqua-pos.com
    </span>
   </div>
    <div class="socials">
      <a href="https://www.facebook.com/profile.php?id=61555432894814&mibextid=ZbWKwL" target="_blank" class="ion ion-social-facebook"></a>
      <a href="https://www.instagram.com/aqua_software?igsh=bHR3eGVtb3c3cDBy" target="_blank" class="ion ion-social-instagram"></a>
      <a href="https://www.linkedin.com/company/aqua-software-co" target="_blank" class="ion ion-social-linkedin"></a>
    </div>

  `,
})
export class FooterComponent {
  companyId : string =this.authenticationService.currentUserValue.tenantId;
  constructor(private authenticationService :AuthenticationService)
  {

  }

}
