import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { ResponseObject } from '../../../../../shared/models/response-object';
import { retry, catchError } from 'rxjs/operators';
import { InvStore } from './models/inv-store';
import { runInThisContext } from 'vm';
import { SelectionModel } from '../../../../../shared/models/selection-model';

@Injectable({
  providedIn: 'root'
})
export class StoreService {


  endPoint = environment.endPoint + 'InvMsStore/';
  httpHeader = { headers: new HttpHeaders() };

  constructor(private httpClient: HttpClient) { }

  setHeader() {
    if ((JSON.parse(localStorage.getItem('currentUser')!)) != null) {
      this.httpHeader = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'tenantId': (JSON.parse(localStorage.getItem('currentUser')!)).tenantId,
          'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('currentUser')!)).token
        })
      };
    }
  }

  getLookupByBranchId(id: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + 'GetLookupByBranchId/' + id, this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  getLookup(): Observable<SelectionModel[]> {
    this.setHeader();
    return this.httpClient.get<SelectionModel[]>(this.endPoint + 'GetLookup', this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }




  add(obj: InvStore): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPoint + 'Create', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError))
  }

  update(obj: InvStore): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.put<ResponseObject>(this.endPoint + 'Update', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  getAll(): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + 'GetAll', this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  getLookups(obj): Observable<any> {
    this.setHeader();
    return this.httpClient.put<any>(this.endPoint + 'GetLookups', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  processError(err: any) {
    let message = '';
    if (err.error instanceof ErrorEvent) {
      message = err.error.message;
    } else {
      message = `Error Code: ${err.status}\nMessage: ${err.message}`;
    }

    return throwError(message);
  }
}
