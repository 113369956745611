import { Component, OnInit } from '@angular/core';
import { ChangeYearService } from '../../services/change-year.service';
import { SelectionModel } from '../../../../../../../shared/models/selection-model';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { AuthenticationService } from '../../../../../../../shared/services/authentication.service';
import { TosterService } from '../../../../../../../shared/services/toster.service';
import { ConfirmationComponent } from '../../../../../../../shared/components/confirmation/confirmation/confirmation.component';
import { CurrentYear } from '../../../../../../../shared/components/auth/signin/models/current-year';
import { WarningComponent } from '../../../../../../../shared/components/warning/warning.component';

@Component({
  selector: 'ngx-change-year-list',
  templateUrl: './change-year-list.component.html',
  styleUrls: ['./change-year-list.component.scss']
})
export class ChangeYearListComponent implements OnInit {

  //#region  Variable
  form: FormGroup;
  loading: boolean = false;
  yearList: SelectionModel[];


  //#endregion
  constructor(private dialog: NbDialogRef<any>, private dialogService: NbDialogService, private changeYearService: ChangeYearService, private authenticationService: AuthenticationService, private router: Router, private tosterService: TosterService) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      year: new FormControl(null),
    });
    this.getSelections();
  }

  getSelections() {
    this.changeYearService.getLookup(this.authenticationService.currentCompanyValue.company.id).subscribe(
      res => { this.yearList = res.data; },
      error => this.HandelingError(error)
    );
  }

  yearSelectedChange(event) {
    if (event != this.authenticationService.currentYearValue.year) {
      this.dialogService.open(ConfirmationComponent,
        {
          context: { message: "Are you sure you want to Change Year To " + event + " ?" },
          closeOnBackdropClick: false, autoFocus: true, dialogClass: 'model-full'
        })
        .onClose.subscribe(rep => {
          if (rep) {
            this.loading = true;
            this.changeYearService.changeYear(this.authenticationService.currentCompanyValue.company.id, event,this.authenticationService.currentYearValue.year).subscribe(
              res => {
                if (res.code == 302) {
                  var yearObj = new CurrentYear();
                  yearObj.year = res.data;
                  this.authenticationService.setCurrentYearValue = yearObj;
                  this.close(null);
                  this.router.navigate(['pages/modules/home']);
                } else if (res.code == 204) {
                  this.tosterService.showToast('warning', res.message);
                }
                this.loading = false;
              }, error => this.HandelingError(error)
            );
          }
        });
    }
    else this.callWarningComponent('You are in the current Year !')

  }

  addNewYear(event) {
    var year;
    if (event == 'plus')
      year = Number((this.yearList[this.yearList.length - 1]).id) + 1;
    else {
      year = Number((this.yearList[0]).id) - 1;
    }
    this.dialogService.open(ConfirmationComponent,
      {
        context: { message: "Are you sure you want to Add " + year + " Year ?" },
        closeOnBackdropClick: false, autoFocus: true, dialogClass: 'model-full'
      })
      .onClose.subscribe(rep => {
        if (rep) {
          if (event != 'plus') {
            const result: string[] = ((this.yearList[0]).name).split("-");
            if (result[1] != " Open ") {
              this.callWarningComponent('There is closed year after This year');
            }
            else {
              this.handelingAddNewYear(year);
            }
          } else
            this.handelingAddNewYear(year);
        }
      });
  }


  handelingAddNewYear(year) {
    this.loading = true;
    this.changeYearService.addNewYear(this.authenticationService.currentCompanyValue.company.id, year).subscribe(res => {
      if (res.code == 201) {
        this.tosterService.showToast('success', res.message);
        this.close(null);
      } else if (res.code == 400) {
        this.tosterService.showToast('warning', res.message);
      }
      else if (res.code == 401) {
        this.tosterService.showToast('warning', res.message);
        this.close(null);
      }
      this.loading = false;
    },
      error => this.HandelingError(error)
    );
  }

  HandelingError(error: string) {
    if (error.includes("401") || error.includes("500") || error.includes("0")) {
      this.tosterService.showToast("warning", "Your Session Has Expired Please Login In");
      setTimeout(() => {
        this.authenticationService.Logout();
        this.router.navigate(['/sign-in']);
      }, 3000);
    }
  }

  callWarningComponent(message) {
    this.dialogService.open(WarningComponent,
      {
        context: { message: message },
        closeOnBackdropClick: false, autoFocus: true, dialogClass: 'model-full'
      })
  }

  close(data) {
    this.dialog.close(data);
  }



}
