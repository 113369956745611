
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SigninComponent } from './components/auth/signin/signin.component';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbSecurityModule } from '@nebular/security';
import { NbLayoutModule, NbMenuModule, NbUserModule, NbActionsModule, NbSearchModule, NbSidebarModule, NbContextMenuModule, NbButtonModule, NbSelectModule, NbIconModule, NbListModule, NbCardModule, NbSpinnerModule, NbInputModule, NbFormFieldModule, NbTooltipModule, NbRadioModule } from '@nebular/theme';
import { ConfirmationComponent } from './components/confirmation/confirmation/confirmation.component';
import { ImportExcelComponent } from './components/import-excel/import-excel.component';
import { WarningComponent } from './components/warning/warning.component';



const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  NbListModule,
  NbCardModule,
  NbRadioModule

];

@NgModule({
  declarations: [SigninComponent, ConfirmationComponent, ImportExcelComponent, WarningComponent],
  imports: [
    CommonModule,
    FormsModule,
    NB_MODULES,
    ReactiveFormsModule,
    ReactiveFormsModule,
    NbSpinnerModule,
    NbInputModule,
    NbFormFieldModule,
    NbCardModule,
    NbTooltipModule

  ]
})
export class SheredModule { }
