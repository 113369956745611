import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { AuthenticationService } from '../../../../../../../shared/services/authentication.service';
import { TosterService } from '../../../../../../../shared/services/toster.service';
import { WebUserService } from '../../../webUser/WebUserService';

@Component({
  selector: 'ngx-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {

  //#region Variable

  form: FormGroup;
  submitted = false;
  cardHeader: String;
  changePasswordSubmitted = false;
  changePasswordForm: FormGroup;
  userPictureSrc: string = null!;
  userPictureSrcSize: boolean = false;

  //#endregion

  constructor(private authenticationService: AuthenticationService, private dialogService: NbDialogService, private dialog: NbDialogRef<ProfileComponent>,
    private router: Router, private tosterService: TosterService, private WebUserService: WebUserService, private fb: FormBuilder) {


  }

  //#region  Action

  ngOnInit(): void {

    this.form = new FormGroup({
      id: new FormControl(),
      email: new FormControl(),
      active: new FormControl(),
      isAdmin: new FormControl(),
      companyId: new FormControl(),
      passwordHash: new FormControl(),
      userPicture: new FormControl(),
      creationDate: new FormControl(),
      webAccessaRoleId: new FormControl(),
      userName: new FormControl('', [Validators.required , Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]),
      phoneNumber: new FormControl('', [Validators.required , Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]),
    });

    this.changePasswordForm = new FormGroup({
      id: new FormControl(this.authenticationService.currentUserValue.webUser.id),
      newPassword: new FormControl('', [Validators.required , Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]),
      currentPassword: new FormControl('', [Validators.required , Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]),
      confirmPassword: new FormControl('', [Validators.required , Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]),
    }, this.mustMatch('newPassword', 'confirmPassword'));
    this.getById();

  }

  get formValidation(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  get changePasswordFormValidation(): { [key: string]: AbstractControl } {
    return this.changePasswordForm.controls;
  }

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
      return null;
    };
  }

  getById()
  {
    this.WebUserService.getById(this.authenticationService.currentUserValue.webUser.id,false).subscribe(
      res => {
        if (res.code == 302) {
          this.patchForm(res.data);
        }
        else if (res.code == 204) { this.tosterService.showToast('warning', res.message); }
      },
      error => this.HandelingError(error)
    );
  }

  patchForm(data) {
    this.form.patchValue(data);
    this.userPictureSrc = data.userPicture;
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.valid) {
      this.WebUserService.updateUserInfo(this.form.value).subscribe(res => {
        if (res.code == 701) {
          this.tosterService.showToast('success', res.message);
          this.close();
        } else if (res.code == 400) {
          this.tosterService.showToast('warning', res.message);
  
        }
        else if (res.code == 200) {
          this.tosterService.showToast('warning', res.message);
        }
        this.submitted = false;
      },
        error => this.HandelingError(error)
      );
    }
  }

  HandelingError(error: string) {
    if (error.includes("401") || error.includes("500") || error.includes("0")) {
      this.tosterService.showToast("warning", "Your Session Has Expired Please Login In");
      setTimeout(() => {
        this.authenticationService.Logout();
        this.router.navigate(['/sign-in']);
    }, 3000);  
    }
  }

  onChangePicture(event: any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      if (file.size / 1024 <= 500) {
        this.userPictureSrcSize = false;
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.userPictureSrc = reader.result as string;
          this.form.patchValue({
            userPicture: reader.result,
          });
        };
        this.form.get('userPicture').updateValueAndValidity();
      } else {
        this.userPictureSrcSize = true;
      }
    }

  }

  clearLogo() {
    this.userPictureSrc = null!;
    this.userPictureSrcSize = false;
    this.form.patchValue({
      userPicture: null,
    });
  this.form.get('userPicture').updateValueAndValidity();
  }

  changePassword() {
    this.changePasswordSubmitted = true;
    if (this.changePasswordForm.valid) {
      this.WebUserService.changePassword(this.changePasswordForm.value).subscribe(res => {
        if (res.code == 701) {
          this.tosterService.showToast('success', res.message);
          this.authenticationService.signup()
      .subscribe(
        res => {
           if (res.code==201)
            this.router.navigate(['/sign-in']);
        },
        error => {}
      );
         
        } else if (res.code == 400) {
          this.tosterService.showToast('warning', res.message);
        }
        else if (res.code == 200) {
          this.tosterService.showToast('warning', res.message);
        }
        this.changePasswordSubmitted = false;
      },
        error => this.HandelingError(error)
      );
    }
  }

  close() { this.dialog.close(); }

  //#endregion

}
