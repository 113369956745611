import { Signin } from './../components/auth/signin/models/signin';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { SigninCredentials } from '../components/auth/signin/models/signinCredentials';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponseObject } from '../models/response-object';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { retry, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CurrentYear } from '../components/auth/signin/models/current-year';
import { CurrentCompany } from '../components/auth/signin/models/current-company';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements OnDestroy {

  //#region  Variable
  signinObj: Signin;
  yearObj: CurrentYear;
  companyObj: CurrentCompany;

  httpHeader = { headers: new HttpHeaders() };
  authHttpHeader = { headers: new HttpHeaders() };

  endPointAuth = environment.endPoint + 'Auth/';
  public currentUser: Observable<Signin>;
  public currentYear: Observable<CurrentYear>;
  public currentCompany: Observable<CurrentCompany>;
  public currentUserSubject: BehaviorSubject<Signin>;
  public currentYearSubject: BehaviorSubject<CurrentYear>;
  public currentCompanySubject: BehaviorSubject<CurrentCompany>;
  public isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  //#endregion

  constructor(private httpClient: HttpClient, private router: Router) {

    this.currentUserSubject = new BehaviorSubject<Signin>(JSON.parse(localStorage.getItem('currentUser')!));
    this.currentYearSubject = new BehaviorSubject<CurrentYear>(JSON.parse(localStorage.getItem('currentYear')!));
    this.currentCompanySubject = new BehaviorSubject<CurrentCompany>(JSON.parse(localStorage.getItem('currentCompany')!));
    this.currentUser = this.currentUserSubject.asObservable();
    this.currentYear = this.currentYearSubject.asObservable();
    this.currentCompany = this.currentCompanySubject.asObservable();
  }
  ngOnDestroy(): void {

  }

  public get currentUserValue(): Signin {
    return this.currentUserSubject.value;
  }

  public get currentYearValue(): CurrentYear {
    return this.currentYearSubject.value;
  }
  public get currentCompanyValue(): CurrentCompany {
    return this.currentCompanySubject.value;
  }

  public set setCurrentYearValue(obj) {
    this.currentYearSubject.next(obj);
    localStorage.setItem('currentYear', JSON.stringify(obj));

  }

  public set setCurrentCompanyValue(obj) {
    this.currentCompanySubject.next(obj);
    localStorage.setItem('currentCompany', JSON.stringify(obj));
  }


  setHeader(companyId) {
    this.httpHeader = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'tenantId': companyId,
        'Authorization': 'Bearer '
      })
    }
  }

  setAuthHeader() {
    this.authHttpHeader = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'tenantId': this.currentUserValue.tenantId,
        'Authorization': 'Bearer ' + this.currentUserValue.token,
      })
    };
  }

  checkPreviousLogin(obj: SigninCredentials, companyId: string) {
    this.setHeader(companyId);
    return this.httpClient.post<ResponseObject>(this.endPointAuth + 'CheckPreviousLogin', JSON.stringify(obj), this.httpHeader)
      .pipe(map(response => {
        return response;
      }));
  }


  signin(obj: SigninCredentials, companyId: string) {
    this.setHeader(companyId);
    return this.httpClient.post<ResponseObject>(this.endPointAuth + 'Login', JSON.stringify(obj), this.httpHeader)
      .pipe(map(response => {
        if (response.code == 300 || response.code == 200) { return response; }
        else {
          this.signinObj = new Signin();
          this.yearObj = new CurrentYear();
          this.companyObj = new CurrentCompany();
          this.signinObj.loggedin = true;
          this.signinObj.tenantId = companyId;
          this.signinObj.token = response.data.token;
          this.signinObj.webUser = response.data.webUser;
          this.signinObj.invExpanderConfigs = response.data.invExpanderConfigs;
          this.signinObj.invBranchStoreConfigs = response.data.invBranchStoreConfigs;
          this.signinObj.invNotificationCountsDTO = response.data.invNotificationCountsDTO;
          this.signinObj.httpHeader = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'tenantId': companyId,
              'Authorization': 'Bearer ' + this.signinObj.token
            })
          }
          //Handeling Accounting Info
          if (this.signinObj.webUser.webAccessaRole.webAccessPermissions.filter(l => l.permissionName == "Accounting").length == 1) {
            this.yearObj.year = response.data.currentCompanyYearDTO.currentYear;
            this.companyObj.company = response.data.currentCompanyYearDTO.currentCompany;
            this.companyObj.webUserAllowedCompanies = response.data.currentCompanyYearDTO.webUserAllowedCompanies;
            localStorage.setItem('currentYear', JSON.stringify(this.yearObj));
            localStorage.setItem('currentCompany', JSON.stringify(this.companyObj));
            this.currentYearSubject.next(this.yearObj);
            this.currentCompanySubject.next(this.companyObj);
          }
          localStorage.setItem('currentUser', JSON.stringify(this.signinObj));
          this.currentUserSubject.next(this.signinObj);
          return response;
        }
      }));
  }

  forgetPassword(email: String, companyId: string) {
    debugger;
    this.setHeader(companyId);
    return this.httpClient.post<ResponseObject>(this.endPointAuth + 'ForgetPassword', JSON.stringify(email), this.httpHeader)
      .pipe(map(response => {
        return response;
      }));
  }

  signup() {
    debugger
    this.setAuthHeader();
    return this.httpClient.get<ResponseObject>(this.endPointAuth + 'Logout', this.authHttpHeader)
      .pipe(map(response => {
        this.currentUserSubject.next(null!);
        this.ngOnDestroy();
        return response;
      }));
  }

  Logout() {
    this.currentUserSubject.next(null!);
    this.ngOnDestroy();
  }

  processError(err: any) {
    let message = '';
    if (err.error instanceof ErrorEvent) {
      message = err.error.message;
    } else {
      message = `Error Code: ${err.status}\nMessage: ${err.message}`;
    }
    return throwError(message);
  }
}


