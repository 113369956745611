import { HttpHeaders } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Signin } from './models/signin';
import { Subscription } from 'rxjs';
import { SigninCredentials } from './models/signinCredentials';
import { AuthenticationService } from '../../../services/authentication.service'
import { NbComponentStatus, NbDialogService, NbToastrService } from '@nebular/theme';
import { Compiler } from '@angular/core';
import { TosterService } from '../../../services/toster.service';
import { ConfirmationComponent } from '../../confirmation/confirmation/confirmation.component';


@Component({
  selector: 'ngx-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  //#region  Variable
  loading = false;
  loginForm: FormGroup;
  isSubmitted = false;
  showPassword = false;
  sessionUser: Signin;
  webuser: SigninCredentials;
  get formControls() { return this.loginForm.controls; }

  //#endregion

  constructor(private router: Router, private formBuilder: FormBuilder, private authenticationService: AuthenticationService,
    private tosterService: TosterService, private dialogService: NbDialogService) {

  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      companyID: ['', Validators.required],
      password: ['', Validators.required],
    });

  }

  @HostListener('document:keypress', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode == 13) {
      event.preventDefault();
      this.checkPreviousLogin();
    }
  }


  getInputType() {
    if (this.showPassword) {
      return 'text';
    }
    return 'password';
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  forgetPassword() {
    
    if (this.loginForm.controls.email.valid && this.loginForm.controls.companyID.valid) {
      this.loading = true;
      this.authenticationService.forgetPassword(this.loginForm.get('email').value, this.loginForm.get('companyID').value).subscribe(
        res => this.handselingForgetPasswordResponse(false, res),
        error => this.handselingForgetPasswordResponse(true, error));
    }
  }


  openForgetPasswordConfirmationDialog() {
    if (this.loginForm.controls.email.valid && this.loginForm.controls.companyID.valid) {
    this.dialogService.open(ConfirmationComponent,
      {
        context: { message: "Fortget Password ?" },
        closeOnBackdropClick: false,
        autoFocus: true,
        dialogClass: 'model-full'
      })
      .onClose.subscribe(rep => {
        if (rep) { this.forgetPassword(); }
      });
    }
  }

  checkPreviousLogin() {
    if (this.loginForm.valid) {
      let signinCredentials = new SigninCredentials();
      signinCredentials.password = this.loginForm.get('password').value;
      signinCredentials.username = this.loginForm.get('email').value;
      this.authenticationService.checkPreviousLogin(signinCredentials, this.loginForm.get('companyID').value).subscribe(
        res => this.handselingcheckPreviousLoginResponse(false, res),
        error => this.handselingcheckPreviousLoginResponse(true, error)
      );
    }
  }

  openConfirmationDialog() {
    this.dialogService.open(ConfirmationComponent,
      {
        context: { message: "Your account already sign in with another browser!" },
        closeOnBackdropClick: false,
        autoFocus: true,
        dialogClass: 'model-full'
      })
      .onClose.subscribe(rep => {
        if (rep) { this.login(); }
      });
  }

  login() {
    if (this.loginForm.valid) {
      let signinCredentials = new SigninCredentials();
      signinCredentials.password = this.loginForm.get('password').value;
      signinCredentials.username = this.loginForm.get('email').value;
      this.loading = true;
      this.authenticationService.signin(signinCredentials, this.loginForm.get('companyID').value).subscribe(
        res =>{ this.handselingResponse(false, res); },
        error => this.handselingResponse(true, error)
      );
    }

  }


  handselingResponse(error: Boolean, res: any) {
    if (!error) {
      debugger;
      if (res.code == 302) {
        this.router.navigate(['/pages/modules/home']);
      } else if (res.code == 300 || res.code == 200) {
        this.tosterService.showToast('warning', res.message);
      }
      else {
        this.tosterService.showToast('warning', "Invalid Email Or Password");
      }
    } else {
      this.tosterService.showToast('warning', " Comapny Id Not Found");
    }
    this.loading = false;
  }

  handselingcheckPreviousLoginResponse(error: Boolean, res: any) {
    if (!error) {
      if (res.code == 200 && res.data != null) {
        this.openConfirmationDialog();
      } else if (res.code == 201 && res.data == null) {
        this.login();
      }
      else if (res.code == 300) {
        this.tosterService.showToast('warning', res.message);
      }

    } else {
      this.tosterService.showToast('warning', "Some Thing Is Wrong");
    }
  }


  handselingForgetPasswordResponse(error: Boolean, res: any) {
    if (!error) {
      if (res.code == 701) {
        this.tosterService.showToast('success', res.message);
      } else if (res.code == 400 || res.code == 204) {
        this.tosterService.showToast('warning', res.message);
      }
    } else {
      this.tosterService.showToast('warning', " Comapny Id Not Found");
    }
    this.loading = false;
  }
}
