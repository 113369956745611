
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';

import { SigninComponent } from '../shared/components/auth/signin/signin.component';
import { AuthGuard } from './helpers/auth.guard';
import { HomeComponent } from './pages/modules/home/components/home.component';


export const routes: Routes = [

  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
      canActivate: [AuthGuard],
  },

  {
    path: 'sign-in',
    component: SigninComponent,
    // canActivate: [AuthGuard],
  },

  { path: '', redirectTo: 'pages', pathMatch: 'full' },

];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
