import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';
import { ResponseObject } from '../../../../../../shared/models/response-object';
import { SalesItem } from '../models/sales-item';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { SelectionModel } from '../../../../../../shared/models/selection-model';
import { SalesItemImport } from '../models/sales-item-import';



@Injectable({
  providedIn: 'root'
})
export class SalesItemService {

  endPoint = environment.endPoint + 'SalesItem/';
  endPointInvMsRecipe = environment.endPoint + 'InvMsRecipe/';
  endPointBarcode = environment.endPoint + 'BarCode/';
  endPointBranchItemPrice = environment.endPoint + 'BranchItemPrice/';
  httpHeader = { headers: new HttpHeaders() };

  constructor(private httpClient: HttpClient) { }

  setHeader() {
    if ((JSON.parse(localStorage.getItem('currentUser')!)) != null) {
      this.httpHeader = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'tenantId': (JSON.parse(localStorage.getItem('currentUser')!)).tenantId,
          'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('currentUser')!)).token
        })
      };
    }
  }

  getAll(): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + 'GetAllByGroupId', this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  getRecipes(groupId, type): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + 'GetAll?groupId=' + groupId + '&type=' + type, this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  getAllByGroupId(id): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + 'GetAllByGroupId?id=' + id, this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  getAllByBranchIdAndGroupId(branchId, groupId): Observable<ResponseObject> {
    this.setHeader();

    return this.httpClient.post<ResponseObject>(this.endPoint + 'GetAllByBranchIdAndGroupId',
      JSON.stringify({ groupID: groupId, branchID: branchId }), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  getAllByBranchId(id): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + 'GetAllByBranchId?id=' + id, this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  getById(id?: any): Observable<ResponseObject> {
    this.setHeader();
    if (id == null)
      return this.httpClient.get<ResponseObject>(this.endPoint + 'GetById', this.httpHeader)
        .pipe(
          retry(1),
          catchError(this.processError)
        )
    else
      return this.httpClient.get<ResponseObject>(this.endPoint + 'GetById?id=' + id, this.httpHeader)
        .pipe(
          retry(1),
          catchError(this.processError)
        )
  }

  getGroupPrinters(): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + 'getGroupPrinters', this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )

  }

  getLookup(): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + 'GetLookup', this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  add(obj: SalesItem): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPoint + 'Create', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError))
  }

  update(obj: SalesItem): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.put<ResponseObject>(this.endPoint + 'Update', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError))
  }

  updateGrroupPrinters(obj: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.put<ResponseObject>(this.endPoint + 'UpdateGrroupPrinters', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError))
  }

  getScreensetup(obj: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.put<ResponseObject>(this.endPoint + 'GetScreensetupLookup', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }


  getSalesItemByGroup(ids: Array<number>): Observable<SelectionModel[]> {
    this.setHeader();
    return this.httpClient.post<SelectionModel[]>(this.endPoint + 'GetSalesItemByGroup', JSON.stringify(ids), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  importXLS(obj: SalesItemImport[]): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPoint + 'ImportXLS', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError))
  }

  GetBarcodeDTOB(obj: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPointBarcode + 'GetBarcodePrint', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }
  
  getPriceValue(salesItemId: number, branchId: number, priceModeId: number): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPointBranchItemPrice + 'GetPriceValue?branchId=' + branchId + '&salesItemId=' + salesItemId +'&priceModeId=' + priceModeId, this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  getBranchTaxAllowed(id: number, branchId: number): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + 'GetBranchTaxAllowed?id=' + id + '&branchId=' + branchId, this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  getItemSearch(search: string, branchId: number, priceModeId: number): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + 'GetItemSearch?search=' + search + '&branchId=' + branchId + '&priceModeId=' + priceModeId, this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  
  getItemSearchWithTax(search: string, branchId: number): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + 'GetItemSearchWithTax?search=' + search + '&branchId=' + branchId , this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }


  RecalculateRecipe(): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPointInvMsRecipe + 'RecalculateRecipe', this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }




  processError(err: any) {
    let message = '';
    if (err.error instanceof ErrorEvent) {
      message = err.error.message;
    } else {
      message = `Error Code: ${err.status}\nMessage: ${err.message}`;
    }
    return throwError(message);
  }

}
