<nb-card accent="info">
    <nb-card-body>
        <h6> {{message}} </h6>
    </nb-card-body>
    <nb-card-footer>
        <div class="row">
            <div class="col-md-6">
                <button nbButton size="small" status="info" fullWidth shape="semi-round" (click)="close(true)">
                    Yes
                </button>
            </div>
            <div class="col-md-6">
                <button nbButton status="danger" size="small" fullWidth shape="semi-round" (click)="close(false)">
                    No
                </button>
            </div>
        </div>

    </nb-card-footer>
</nb-card>