import { BranchItemPricesImport } from "./branch-item-prices-import";

export class SalesItemImport {
    id: number;
    code: string;
    groupId: number;
    barcode1: string;
    barcode2: string;
    barcode3: string;
    avilable: boolean;
    describtion: string;
    itemFunctionId: number;
    menuDiscribtion: string;
    kichenDiscribtion: string;
    branchItemPrices = Array<BranchItemPricesImport>();

}
