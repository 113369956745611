import { Injectable } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../../../../../../shared/services/authentication.service';
import { ResponseObject } from '../../../../../../shared/models/response-object';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  endPoint = environment.endPoint + 'AccCompany/';
  httpHeader = { headers: new HttpHeaders() };


  constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) { }

  setHeader() {
    if ((JSON.parse(localStorage.getItem('currentUser')!)) != null) {
      this.httpHeader = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'tenantId': (JSON.parse(localStorage.getItem('currentUser')!)).tenantId,
          'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('currentUser')!)).token
        })
      };
    }
  }


  changeCompany(companyId,webuserId): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + 'ChangeCompany?companyId='+ companyId +'&webuserId=' + webuserId, this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  getAll(webUserId): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + 'GetAll?webUserId='+ webUserId, this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }


  getById(id: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + 'GeById?id=' + id, this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  
  add(obj:any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPoint + 'Create', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError))
  }


  update(obj: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.put<ResponseObject>(this.endPoint + 'Update', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  processError(err: any) {
    let message = '';
    if (err.error instanceof ErrorEvent) {
      message = err.error.message;
    } else {
      message = `Error Code: ${err.status}\nMessage: ${err.message}`;
    }

    return throwError(message);
  }

}

