import { Injectable } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Observable, throwError } from 'rxjs';
import { ResponseObject } from '../../../../../../shared/models/response-object';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InventoryReportService {
  endPointStockReport = environment.endPoint + 'StockReport/';
  endPointStockReportHistory = environment.endPoint + 'StockReportHistory/';
  endPointStockReportBySupplier = environment.endPoint + 'StockReportBySupplier/';
  endPointStockReportByCategory = environment.endPoint + 'StockReportByCategory/';
  endPointItemReViewReport = environment.endPoint + 'ItemReViewReport/';
  endPointItemTransactionReport = environment.endPoint + 'ItemTransactionReport/';
  endPointStockReportSelling = environment.endPoint + 'StockReportSelling/';
  endPointReorderingItemReport = environment.endPoint + 'ReorderingItemReport/';
  endPointOverStockItemReport = environment.endPoint + 'OverStockItemReport/';
  endPointCostVariationReport = environment.endPoint + 'CostVariationReport/';
  endPointMenuEngineeringReport = environment.endPoint + 'MenuEngineeringReport/';
  endPointProfitByItemReport = environment.endPoint + 'ProfitByItemReport/';
  endPointProfitByItemByMenuReport = environment.endPoint + 'ProfitByItemByMenuReport/';
  endPointTheoreticalConsumptionReport = environment.endPoint + 'TheoreticalConsumptionReport/';
  endPointSalesVSPurchaseReport = environment.endPoint + 'SalesVSPurchaseReport/';

  httpHeader = { headers: new HttpHeaders() };

  constructor(private httpClient: HttpClient, private datepipe: DatePipe) { }


  setHeader() {
    if ((JSON.parse(localStorage.getItem('currentUser')!)) != null) {
      this.httpHeader = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'tenantId': (JSON.parse(localStorage.getItem('currentUser')!)).tenantId,
          'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('currentUser')!)).token
        })
      };
    }
  }
  parseEODReport(obj: any) {
    if (obj.eODDate != null && obj.eODDate != "")
      obj.eODDate = (this.datepipe.transform((obj.eODDate).replace(/(\d{2})-(\d{2})-(\d{4})/, "$2-$1-$3"), 'yyyy-MM-dd', 'es-ES'));
    else {
      obj.fromDate = new Date(this.datepipe.transform((new Date(obj.fromDate)), 'yyyy-MM-dd'));
      obj.toDate = new Date(this.datepipe.transform((new Date(obj.toDate)), 'yyyy-MM-dd'));
    }
  }

  processError(err: any) {
    let message = '';
    if (err.error instanceof ErrorEvent) {
      message = err.error.message;
    } else {
      message = `Error Code: ${err.status}\nMessage: ${err.message}`;
    }
    return throwError(message);
  }

  //#region PDF
  GetStockReportDTO(obj: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPointStockReport + 'GetStockReportDTO', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetStockReportHistoryDTO(obj: any): Observable<ResponseObject> {
    this.setHeader();
    obj.date = new Date(this.datepipe.transform((new Date(obj.date)), 'yyyy-MM-dd'));
    return this.httpClient.post<ResponseObject>(this.endPointStockReportHistory + 'GetStockReportHistoryDTO', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetStockReportBySupplier(obj: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPointStockReportBySupplier + 'GetStockReportBySupplier', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetStockReportByCategoryDTO(obj: any): Observable<ResponseObject> {
    this.setHeader();
    obj.date = new Date(this.datepipe.transform((new Date(obj.date)), 'yyyy-MM-dd'));
    return this.httpClient.post<ResponseObject>(this.endPointStockReportByCategory + 'GetStockReportByCategoryDTO', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }


  GetItemReviewReportDTO(obj: any): Observable<ResponseObject> {
    this.setHeader();
    console.log(obj);
    obj.date = new Date(this.datepipe.transform((new Date(obj.date)), 'yyyy-MM-dd'));
    obj.fromDate = new Date(this.datepipe.transform((new Date(obj.fromDate)), 'yyyy-MM-dd'));
    obj.toDate = new Date(this.datepipe.transform((new Date(obj.toDate)), 'yyyy-MM-dd'));
    return this.httpClient.post<ResponseObject>(this.endPointItemReViewReport + 'GetItemReviewReportDTO', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetItemTransactionReportDTO(obj: any): Observable<ResponseObject> {
    this.setHeader();
    console.log(obj);
    obj.date = new Date(this.datepipe.transform((new Date(obj.date)), 'yyyy-MM-dd'));
    obj.fromDate = new Date(this.datepipe.transform((new Date(obj.fromDate)), 'yyyy-MM-dd'));
    obj.toDate = new Date(this.datepipe.transform((new Date(obj.toDate)), 'yyyy-MM-dd'));
    return this.httpClient.post<ResponseObject>(this.endPointItemTransactionReport + 'GetItemTransactionReportDTO', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetStocktockReportSellingDTO(obj: any): Observable<ResponseObject> {
    this.setHeader();
    console.log(obj);
    obj.date = new Date(this.datepipe.transform((new Date(obj.date)), 'yyyy-MM-dd'));
    obj.fromDate = new Date(this.datepipe.transform((new Date(obj.fromDate)), 'yyyy-MM-dd'));
    obj.toDate = new Date(this.datepipe.transform((new Date(obj.toDate)), 'yyyy-MM-dd'));
    return this.httpClient.post<ResponseObject>(this.endPointStockReportSelling + 'GetStocktockReportSellingDTO', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetReorderingItemReportDTO(obj: any): Observable<ResponseObject> {
    this.setHeader();
    console.log(obj);
    obj.date = new Date(this.datepipe.transform((new Date(obj.date)), 'yyyy-MM-dd'));
    obj.fromDate = new Date(this.datepipe.transform((new Date(obj.fromDate)), 'yyyy-MM-dd'));
    obj.toDate = new Date(this.datepipe.transform((new Date(obj.toDate)), 'yyyy-MM-dd'));
    return this.httpClient.post<ResponseObject>(this.endPointReorderingItemReport + 'GetReorderingItemReportDTO', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetOverStockItemReportDTO(obj: any): Observable<ResponseObject> {
    this.setHeader();
    console.log(obj);
    obj.date = new Date(this.datepipe.transform((new Date(obj.date)), 'yyyy-MM-dd'));
    obj.fromDate = new Date(this.datepipe.transform((new Date(obj.fromDate)), 'yyyy-MM-dd'));
    obj.toDate = new Date(this.datepipe.transform((new Date(obj.toDate)), 'yyyy-MM-dd'));
    return this.httpClient.post<ResponseObject>(this.endPointOverStockItemReport + 'GetOverStockItemReportDTO', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetCostVariationReportDTO(obj: any): Observable<ResponseObject> {
    this.setHeader();
    console.log(obj);
    obj.date = new Date(this.datepipe.transform((new Date(obj.date)), 'yyyy-MM-dd'));
    obj.fromDate = new Date(this.datepipe.transform((new Date(obj.fromDate)), 'yyyy-MM-dd'));
    obj.toDate = new Date(this.datepipe.transform((new Date(obj.toDate)), 'yyyy-MM-dd'));
    return this.httpClient.post<ResponseObject>(this.endPointCostVariationReport + 'GetCostVariationReportDTO', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }


  GetMenuEngineeringReportDTO(obj: any): Observable<ResponseObject> {
    this.setHeader();
    console.log(obj);
    obj.date = new Date(this.datepipe.transform((new Date(obj.date)), 'yyyy-MM-dd'));
    obj.fromDate = new Date(this.datepipe.transform((new Date(obj.fromDate)), 'yyyy-MM-dd'));
    obj.toDate = new Date(this.datepipe.transform((new Date(obj.toDate)), 'yyyy-MM-dd'));
    return this.httpClient.post<ResponseObject>(this.endPointMenuEngineeringReport + 'GetMenuEngineeringReportDTO', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetProfitByItemReportDTO(obj: any): Observable<ResponseObject> {
    this.setHeader();
    console.log(obj);
    obj.date = new Date(this.datepipe.transform((new Date(obj.date)), 'yyyy-MM-dd'));
    obj.fromDate = new Date(this.datepipe.transform((new Date(obj.fromDate)), 'yyyy-MM-dd'));
    obj.toDate = new Date(this.datepipe.transform((new Date(obj.toDate)), 'yyyy-MM-dd'));
    return this.httpClient.post<ResponseObject>(this.endPointProfitByItemReport + 'GetProfitByItemReportDTO', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetProfitByItemByMenuReportDTO(obj: any): Observable<ResponseObject> {
    this.setHeader();
    console.log(obj);
    obj.date = new Date(this.datepipe.transform((new Date(obj.date)), 'yyyy-MM-dd'));
    obj.fromDate = new Date(this.datepipe.transform((new Date(obj.fromDate)), 'yyyy-MM-dd'));
    obj.toDate = new Date(this.datepipe.transform((new Date(obj.toDate)), 'yyyy-MM-dd'));
    return this.httpClient.post<ResponseObject>(this.endPointProfitByItemByMenuReport + 'GetProfitByItemByMenuReportDTO', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetTheoreticalConsumptionReportDTO(obj: any): Observable<ResponseObject> {
    this.setHeader();
    console.log(obj);
    obj.date = new Date(this.datepipe.transform((new Date(obj.date)), 'yyyy-MM-dd'));
    obj.fromDate = new Date(this.datepipe.transform((new Date(obj.fromDate)), 'yyyy-MM-dd'));
    obj.toDate = new Date(this.datepipe.transform((new Date(obj.toDate)), 'yyyy-MM-dd'));
    return this.httpClient.post<ResponseObject>(this.endPointTheoreticalConsumptionReport + 'GetTheoreticalConsumptionReportDTO', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetSalesVSPurchaseReportDTO(obj: any): Observable<ResponseObject> {
    this.setHeader();
    console.log(obj);
    obj.date = new Date(this.datepipe.transform((new Date(obj.date)), 'yyyy-MM-dd'));
    obj.fromDate = new Date(this.datepipe.transform((new Date(obj.fromDate)), 'yyyy-MM-dd'));
    obj.toDate = new Date(this.datepipe.transform((new Date(obj.toDate)), 'yyyy-MM-dd'));
    return this.httpClient.post<ResponseObject>(this.endPointSalesVSPurchaseReport + 'GetSalesVSPurchaseReportDTO', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }
  //#endregion

  //#region XLS


  GetStockReportDTOXLS(obj: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPointStockReport + 'GetStockReportDTOXLS', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetStockReportHistoryDTOXLS(obj: any): Observable<ResponseObject> {
    this.setHeader();
    obj.date = new Date(this.datepipe.transform((new Date(obj.date)), 'yyyy-MM-dd'));
    return this.httpClient.post<ResponseObject>(this.endPointStockReportHistory + 'GetStockReportHistoryDTOXLS', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetStockReportBySupplierXLS(obj: any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPointStockReportBySupplier + 'GetStockReportBySupplierXLS', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetStockReportByCategoryDTOXLS(obj: any): Observable<ResponseObject> {
    this.setHeader();
    obj.date = new Date(this.datepipe.transform((new Date(obj.date)), 'yyyy-MM-dd'));
    return this.httpClient.post<ResponseObject>(this.endPointStockReportByCategory + 'GetStockReportByCategoryDTOXLS', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }


  GetItemReviewReportDTOXLS(obj: any): Observable<ResponseObject> {
    this.setHeader();
    console.log(obj);
    obj.date = new Date(this.datepipe.transform((new Date(obj.date)), 'yyyy-MM-dd'));
    obj.fromDate = new Date(this.datepipe.transform((new Date(obj.fromDate)), 'yyyy-MM-dd'));
    obj.toDate = new Date(this.datepipe.transform((new Date(obj.toDate)), 'yyyy-MM-dd'));
    return this.httpClient.post<ResponseObject>(this.endPointItemReViewReport + 'GetItemReviewReportDTOXLS', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetItemTransactionReportDTOXLS(obj: any): Observable<ResponseObject> {
    this.setHeader();
    console.log(obj);
    obj.date = new Date(this.datepipe.transform((new Date(obj.date)), 'yyyy-MM-dd'));
    obj.fromDate = new Date(this.datepipe.transform((new Date(obj.fromDate)), 'yyyy-MM-dd'));
    obj.toDate = new Date(this.datepipe.transform((new Date(obj.toDate)), 'yyyy-MM-dd'));
    return this.httpClient.post<ResponseObject>(this.endPointItemTransactionReport + 'GetItemTransactionReportDTOXLS', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetStocktockReportSellingDTOXLS(obj: any): Observable<ResponseObject> {
    this.setHeader();
    console.log(obj);
    obj.date = new Date(this.datepipe.transform((new Date(obj.date)), 'yyyy-MM-dd'));
    obj.fromDate = new Date(this.datepipe.transform((new Date(obj.fromDate)), 'yyyy-MM-dd'));
    obj.toDate = new Date(this.datepipe.transform((new Date(obj.toDate)), 'yyyy-MM-dd'));
    return this.httpClient.post<ResponseObject>(this.endPointStockReportSelling + 'GetStocktockReportSellingDTOXLS', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetReorderingItemReportDTOXLS(obj: any): Observable<ResponseObject> {
    this.setHeader();
    console.log(obj);
    obj.date = new Date(this.datepipe.transform((new Date(obj.date)), 'yyyy-MM-dd'));
    obj.fromDate = new Date(this.datepipe.transform((new Date(obj.fromDate)), 'yyyy-MM-dd'));
    obj.toDate = new Date(this.datepipe.transform((new Date(obj.toDate)), 'yyyy-MM-dd'));
    return this.httpClient.post<ResponseObject>(this.endPointReorderingItemReport + 'GetReorderingItemReportDTOXLS', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetOverStockItemReportDTOXLS(obj: any): Observable<ResponseObject> {
    this.setHeader();
    console.log(obj);
    obj.date = new Date(this.datepipe.transform((new Date(obj.date)), 'yyyy-MM-dd'));
    obj.fromDate = new Date(this.datepipe.transform((new Date(obj.fromDate)), 'yyyy-MM-dd'));
    obj.toDate = new Date(this.datepipe.transform((new Date(obj.toDate)), 'yyyy-MM-dd'));
    return this.httpClient.post<ResponseObject>(this.endPointOverStockItemReport + 'GetOverStockItemReportDTOXLS', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetCostVariationReportDTOXLS(obj: any): Observable<ResponseObject> {
    this.setHeader();
    console.log(obj);
    obj.date = new Date(this.datepipe.transform((new Date(obj.date)), 'yyyy-MM-dd'));
    obj.fromDate = new Date(this.datepipe.transform((new Date(obj.fromDate)), 'yyyy-MM-dd'));
    obj.toDate = new Date(this.datepipe.transform((new Date(obj.toDate)), 'yyyy-MM-dd'));
    return this.httpClient.post<ResponseObject>(this.endPointCostVariationReport + 'GetCostVariationReportDTOXLS', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }


  GetMenuEngineeringReportDTOXLS(obj: any): Observable<ResponseObject> {
    this.setHeader();
    console.log(obj);
    obj.date = new Date(this.datepipe.transform((new Date(obj.date)), 'yyyy-MM-dd'));
    obj.fromDate = new Date(this.datepipe.transform((new Date(obj.fromDate)), 'yyyy-MM-dd'));
    obj.toDate = new Date(this.datepipe.transform((new Date(obj.toDate)), 'yyyy-MM-dd'));
    return this.httpClient.post<ResponseObject>(this.endPointMenuEngineeringReport + 'GetMenuEngineeringReportDTOXLS', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetProfitByItemReportDTOXLS(obj: any): Observable<ResponseObject> {
    this.setHeader();
    console.log(obj);
    obj.date = new Date(this.datepipe.transform((new Date(obj.date)), 'yyyy-MM-dd'));
    obj.fromDate = new Date(this.datepipe.transform((new Date(obj.fromDate)), 'yyyy-MM-dd'));
    obj.toDate = new Date(this.datepipe.transform((new Date(obj.toDate)), 'yyyy-MM-dd'));
    return this.httpClient.post<ResponseObject>(this.endPointProfitByItemReport + 'GetProfitByItemReportDTOXLS', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetProfitByItemByMenuReportDTOXLS(obj: any): Observable<ResponseObject> {
    this.setHeader();
    console.log(obj);
    obj.date = new Date(this.datepipe.transform((new Date(obj.date)), 'yyyy-MM-dd'));
    obj.fromDate = new Date(this.datepipe.transform((new Date(obj.fromDate)), 'yyyy-MM-dd'));
    obj.toDate = new Date(this.datepipe.transform((new Date(obj.toDate)), 'yyyy-MM-dd'));
    return this.httpClient.post<ResponseObject>(this.endPointProfitByItemByMenuReport + 'GetProfitByItemByMenuReportDTOXLS', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetTheoreticalConsumptionReportDTOXLS(obj: any): Observable<ResponseObject> {
    this.setHeader();
    console.log(obj);
    obj.date = new Date(this.datepipe.transform((new Date(obj.date)), 'yyyy-MM-dd'));
    obj.fromDate = new Date(this.datepipe.transform((new Date(obj.fromDate)), 'yyyy-MM-dd'));
    obj.toDate = new Date(this.datepipe.transform((new Date(obj.toDate)), 'yyyy-MM-dd'));
    return this.httpClient.post<ResponseObject>(this.endPointTheoreticalConsumptionReport + 'GetTheoreticalConsumptionReportDTOXLS', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  GetSalesVSPurchaseReportDTOXLS(obj: any): Observable<ResponseObject> {
    this.setHeader();
    console.log(obj);
    obj.date = new Date(this.datepipe.transform((new Date(obj.date)), 'yyyy-MM-dd'));
    obj.fromDate = new Date(this.datepipe.transform((new Date(obj.fromDate)), 'yyyy-MM-dd'));
    obj.toDate = new Date(this.datepipe.transform((new Date(obj.toDate)), 'yyyy-MM-dd'));
    return this.httpClient.post<ResponseObject>(this.endPointSalesVSPurchaseReport + 'GetSalesVSPurchaseReportDTOXLS', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }



  GetReorderingItemReportDTOAll(obj: string): Observable<ResponseObject> {
    this.setHeader();
    console.log(obj);
    return this.httpClient.post<ResponseObject>(this.endPointReorderingItemReport + 'GetReorderingItemReportDTOAll', obj, this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }


  //#endregion




}
