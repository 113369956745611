import { InvItemTag } from "../../tag/models/inv-item-tag";
import { InvMaxMin } from "./inv-max-min";

export class InvItem {
    id:number;
    branchId:number;
    groupId:number;
    storeId:number;
    cookingNote:string;
    supplierId:number;
    PurchasingUnitId:number;
    storageUnitId:number;
    sellingUnitId:number;
    productionUnitId:number;
    description:string;
    otherDescription:string;
    sku:string;
    barcode1:string;
    barcode2:string;
    purchaseInStorage:number;
    storageInSelling:number;
    productionQty:number;
    unitCost:number;
    avgCost:number;
    sp1:number;
    sp2:number;
    sp3:number;
    sp4:number;
    dailyCount:boolean;
    weeklyCount:boolean;
    asService:boolean;
    expiryDate:boolean;
    discountinued:boolean;
    hideInReport:boolean;
    option1:boolean;
    option2:boolean;
    option3:boolean;
    caloriesUnitId:number;
    caloriesValue:number;
    picture:string;
    qtyToPrepare:number;
    cookingPicture:string;
    invMsMaxMinDetails=  new  Array<InvMaxMin>();
    invMsItemTags =  new  Array<InvItemTag>();
}
