import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NbDialogRef } from '@nebular/theme';
import { AuthenticationService } from '../../../../../../../shared/services/authentication.service';
import { TosterService } from '../../../../../../../shared/services/toster.service';

@Component({
  selector: 'ngx-end-of-year',
  templateUrl: './end-of-year.component.html',
  styleUrls: ['./end-of-year.component.scss']
})
export class EndOfYearComponent implements OnInit {

  //#region  Variable

  form: FormGroup;
  yearList: [];
  isProceed: boolean = false;


  //#endregion
  constructor(private dialog: NbDialogRef<any>, private authenticationService: AuthenticationService, private router: Router, private tosterService: TosterService) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      year: new FormControl(null),
    });
    this.getSelections();


  }

  getSelections() {
    // this.yearService.getLookup().subscribe(
    //   res => {
    //     this.yearList = res.data;
    //   },
    //   error => this.HandelingError(error)
    // );

  }


  proceedClick() {
    if (this.isProceed != true)
      this.isProceed = true;
    else
      this.isProceed = false;;

  }

  OpenClick() { }

  yearSelectedChange(event) { }


  HandelingError(error: string) {
    if (error.includes("401") || error.includes("500") || error.includes("0")) {
      this.tosterService.showToast("warning", "Your Session Has Expired Please Login In");
      setTimeout(() => {
        this.authenticationService.Logout();
        this.router.navigate(['/sign-in']);
      }, 3000);
    }
  }

  close(data) {
    this.dialog.close(data);
  }

}
