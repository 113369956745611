<div class="header-container">

  <div class="logo-container" >
    
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle" >
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
     <img src="assets/images/Logo.png" height="40" width="160" />

  </div>

</div>

<div class="header-container">
  <nb-actions size="small" >
    <nb-action class="control-item" *ngIf="currentYear!=null && currentCompany!=null" style="font-size: 1rem">{{currentCompany}} - &nbsp; <span style="color: orange;">{{currentYear}}</span></nb-action>
    <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action  *ngIf="notificationMenu.length>0" class="control-item" icon="bell-outline"  badgeText="{{notifictionNo}}"  
    badgeStatus="danger"  badgePosition="top right"  [nbContextMenu]= "notificationMenu"   [nbContextMenuTag]="notificTag"></nb-action>
    <nb-action class="control-item" icon="settings-2-outline" [nbContextMenu]="settingMenu" (menuClick)="onMenuClick($event)">
    </nb-action>
    
    
    
    <nb-action class="user-action"   [nbContextMenu]="userMenu" [nbContextMenuTag]="tag" *nbIsGranted="['view', 'user']" >
      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="user?.name"
               [picture]="user?.picture">
      </nb-user>
    </nb-action>

  </nb-actions>
</div>