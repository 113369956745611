import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { AuthenticationService } from '../../../../../shared/services/authentication.service'
import { WebUser } from './models/web-user';
import { UserDetails } from './models/user-details';
import { ResponseObject } from '../../../../../shared/models/response-object';
import { SigninCredentials } from '../../../../../shared/components/auth/signin/models/signinCredentials';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Console } from 'console';


@Injectable({
  providedIn: 'root'
})
export class WebUserService {
  endPoint = environment.endPoint + 'WebUser/';
  endPointAuth = environment.endPoint + 'Auth/';
  authHttpHeader = { headers: new HttpHeaders() };
  httpHeader = { headers: new HttpHeaders() };

  constructor(private httpClient: HttpClient, private authenticationService:AuthenticationService  ) { }


  setHeader() {
    if ((JSON.parse(localStorage.getItem('currentUser')!)) != null) {
      this.authHttpHeader = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'tenantId': (JSON.parse(localStorage.getItem('currentUser')!)).tenantId,
          'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('currentUser')!)).token
        })
      };
      this.httpHeader = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'tenantId': (JSON.parse(localStorage.getItem('currentUser')!)).tenantId,
          'Authorization': 'Bearer '
        })
      }


    }
  }

  getAll(): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + 'GetAll', this.authHttpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  getById(id: any,accoutntFound:any): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + 'GeById?id=' + id + '&accoutntFound='+ accoutntFound , this.authHttpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  getLookup(): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + 'GetLookup', this.authHttpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  add(obj: UserDetails): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPointAuth + 'Register', JSON.stringify(obj), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError))
  }

  update(obj: WebUser): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.put<ResponseObject>(this.endPoint + 'Update', JSON.stringify(obj), this.authHttpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  

  updateUserInfo(obj: WebUser): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.put<ResponseObject>(this.endPoint + 'UpdateUserInfo', JSON.stringify(obj), this.authHttpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  delete(obj: WebUser): Observable<ResponseObject> {
    debugger;
    return this.httpClient.post<ResponseObject>(this.endPoint + 'Delete', JSON.stringify(obj), this.authHttpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  changePassword(obj) {
    this.setHeader();
    return this.httpClient.post<ResponseObject>(this.endPointAuth + 'ChangePassword', JSON.stringify(obj), this.authHttpHeader)
      .pipe(
        retry(1),
        catchError(this.processError))
  }


  processError(err: any) {
    let message = '';
    if (err.error instanceof ErrorEvent) {
      message = err.error.message;
    } else {
      message = `Error Code: ${err.status}\nMessage: ${err.message}`;
    }

    return throwError(message);
  }






}
