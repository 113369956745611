<div class="model-full">
    <nb-card  [nbSpinner]="loading" nbSpinnerStatus="warning">

        <nb-card-header>
            <h6 style="float:left;color: #fc9b04">Change Company</h6>
            <button style="float:right;" nbButton status="danger" size="medium" (click)="close(null)"><nb-icon
                    icon="close-outline"></nb-icon></button>
        </nb-card-header>

        <nb-card-body>
            <form [formGroup]="form">
                <br>
                <!-- Company form-group -->
                <div class="form-group">
                    <label for="companyId" class="label">Company</label>
                    <nb-select formControlName="companyId" fullWidth placeholder="Company"
                        (selectedChange)="companySelectedChange($event)">
                        <nb-option *ngFor="let company of companyList" [value]="company.id">
                            {{company.name}}</nb-option>
                    </nb-select>
                </div>
                <br>

            </form>
        </nb-card-body>

    </nb-card>

</div>