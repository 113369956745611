import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../../shared/services/authentication.service'


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        debugger;
        if (this.authenticationService.currentUserValue == null) {
            // not logged in so redirect to login page with the return url
            this.router.navigate(['/sign-in'], { queryParams: { returnUrl: state.url } });
            return false;
        } else {
            var module=" Back Office";
            var reportsSubModule="Reports";
            var menuSetupSubModule="Menu Setup";
            var employeeSubModule="Employee";
            var customersSubModule="Customers";
            var configurationsSubModule="Configurations";
            var paymentSettingsSubModule="Payment Settings";
            var urlSpileted = (state.url).split('/');
            if (urlSpileted.length > 3) {
                if (this.authenticationService.currentUserValue.webUser.webAccessaRole.webAccessPermissions.length > 0) {
                    if (urlSpileted[3] == "backoffice") {
                        if (urlSpileted[4] != undefined) {
                            if (this.authenticationService.currentUserValue.webUser.webAccessaRole.webAccessPermissions.find(l=>l.permissionName==module))
                            {
                                if (this.authenticationService.currentUserValue.webUser.webAccessaRole.webAccessPermissions.find(l=>l.permissionName==module).moduleId == 1) {

                                    //#region Menu Setup 
                                    // Handeling (**) Path 
                                    if (urlSpileted[4] == "**") { return true; }
                                    // Handeling (category) Path 
                                    else if (urlSpileted[4] == "category") {
                                        this.HandelingModuleRoute(module, menuSetupSubModule, "Category");
                                    }
                                    // Handeling (menu) Path 
                                    else if (urlSpileted[4] == "menu") {
                                        this.HandelingModuleRoute(module, menuSetupSubModule, "Menu Type");
                                    }
                                    // Handeling (division) Path 
                                    else if (urlSpileted[4] == "division") {
                                        this.HandelingModuleRoute(module, menuSetupSubModule, "Division");
                                    }
                                    // Handeling (screen) Path 
                                    else if (urlSpileted[4] == "screen" || urlSpileted[4] == "screenButtons") {
                                        this.HandelingModuleRoute(module, menuSetupSubModule, "Screens Setup");
                                    }
                                    // Handeling (group) Path 
                                    else if (urlSpileted[4] == "group") {
                                        this.HandelingModuleRoute(module, menuSetupSubModule, "Group");
                                    }
                                    // Handeling (salesItem) Path 
                                    else if (urlSpileted[4] == "salesItem") {
                                        this.HandelingModuleRoute(module, menuSetupSubModule, "Sales Item");
                                    }
                                    // Handeling (salesItemAddEdit) Path 
                                    else if (urlSpileted[4] == "salesItemAddEdit") {
                                        if (((((this.authenticationService.currentUserSubject.value).webUser.webAccessaRole.webAccessPermissions.find(l=>l.permissionName==module)).inverseParent.find(l=>l.permissionName==menuSetupSubModule)).inverseParent.find(x => x.permissionName.trim().toLocaleLowerCase() == "Sales Item".toLocaleLowerCase().trim())) == null) {
                                            this.router.navigate(['/pages/modules/backOffice/']);
                                            return false;
                                        }
                                        else {
                                            if ((((((this.authenticationService.currentUserSubject.value).webUser.webAccessaRole.webAccessPermissions.find(l=>l.permissionName==module)).inverseParent.find(l=>l.permissionName==menuSetupSubModule)).inverseParent.find(x => x.permissionName.trim().toLocaleLowerCase() == "Sales Item".toLocaleLowerCase().trim())).status == true &&
                                                (((((this.authenticationService.currentUserSubject.value).webUser.webAccessaRole.webAccessPermissions.find(l=>l.permissionName==module)).inverseParent.find(l=>l.permissionName==menuSetupSubModule)).inverseParent.find(x => x.permissionName.trim().toLocaleLowerCase() == "Sales Item".toLocaleLowerCase().trim()).inverseParent.find(p => p.permissionName.trim() == "Edit".trim()).status) &&
                                                    urlSpileted.length == 6 ||  urlSpileted.length == 7)
                                                    || ((((this.authenticationService.currentUserSubject.value).webUser.webAccessaRole.webAccessPermissions.find(l=>l.permissionName==module)).inverseParent.find(l=>l.permissionName==menuSetupSubModule)).inverseParent.find(x => x.permissionName.trim().toLocaleLowerCase() == "Sales Item".toLocaleLowerCase().trim()).inverseParent.find(p => p.permissionName.trim() == "Add".trim()).status && urlSpileted.length == 5))
                                            ) { return true;}
                                            else {
                                                this.router.navigate(['/pages/modules/backOffice/']);
                                                return false;
                                            }
                                        }
                                    }
                                    //#endregion
                                    //#region Employee Mangement
                                    // Handeling (employee) Path 
                                    else if (urlSpileted[4] == "employee") {
                                        this.HandelingModuleRoute(module, employeeSubModule, "Employee Setup");
                                    }
                                    // Handeling (salesItemAddEdit) Path 
                                    else if (urlSpileted[4] == "employeeAddEdit") {
                                        if (((((this.authenticationService.currentUserSubject.value).webUser.webAccessaRole.webAccessPermissions.find(l=>l.permissionName==module)).inverseParent.find(l=>l.permissionName==employeeSubModule)).inverseParent.find(x => x.permissionName.trim().toLocaleLowerCase() == "Employee Setup".toLocaleLowerCase().trim())) == null) {
                                            this.router.navigate(['/pages/modules/backOffice/']);
                                            return false;
                                        }
                                        else {
                                            if (((((this.authenticationService.currentUserSubject.value).webUser.webAccessaRole.webAccessPermissions.find(l=>l.permissionName==module)).inverseParent.find(l=>l.permissionName==employeeSubModule)).inverseParent.find(x => x.permissionName.trim().toLocaleLowerCase() == "Employee Setup".toLocaleLowerCase().trim())).status == true &&
                                                (((((this.authenticationService.currentUserSubject.value).webUser.webAccessaRole.webAccessPermissions.find(l=>l.permissionName==module)).inverseParent.find(l=>l.permissionName==employeeSubModule)).inverseParent.find(x => x.permissionName.trim().toLocaleLowerCase() == "Employee Setup".toLocaleLowerCase().trim()).inverseParent.find(p => p.permissionName.trim() == "Edit".trim()).status &&
                                                    urlSpileted.length == 6)
                                                    || ((((this.authenticationService.currentUserSubject.value).webUser.webAccessaRole.webAccessPermissions.find(l=>l.permissionName==module)).inverseParent.find(l=>l.permissionName==employeeSubModule)).inverseParent.find(x => x.permissionName.trim().toLocaleLowerCase() == "Employee Setup".toLocaleLowerCase().trim()).inverseParent.find(p => p.permissionName.trim() == "Add".trim()).status && urlSpileted.length == 5))
                                            ) {
                                                return true;
                                            }
                                            else {
                                                this.router.navigate(['/pages/modules/backOffice/']);
                                                return false;
                                            }
                                        }
                                    }
                                    // Handeling (posRole) Path 
                                    else if (urlSpileted[4] == "posRole") {
                                        this.HandelingModuleRoute(module, employeeSubModule, "Employee Role");
                                    }
                                    else if (urlSpileted[4] == "posRolePermission" && urlSpileted.length == 6) {
                                        if (((((this.authenticationService.currentUserSubject.value).webUser.webAccessaRole.webAccessPermissions.find(l=>l.permissionName==module)).inverseParent.find(l=>l.permissionName==employeeSubModule)).inverseParent.find(x => x.permissionName.trim().toLocaleLowerCase() == "Employee Role".toLocaleLowerCase().trim())) == null) {
                                            this.router.navigate(['/pages/modules/backOffice/']);
                                            return false;
                                        }
                                        else {
                                            if (((((this.authenticationService.currentUserSubject.value).webUser.webAccessaRole.webAccessPermissions.find(l=>l.permissionName==module)).inverseParent.find(l=>l.permissionName==employeeSubModule)).inverseParent.find(x => x.permissionName.trim().toLocaleLowerCase() == "Employee Role".toLocaleLowerCase().trim())).status == true &&
                                                (((((this.authenticationService.currentUserSubject.value).webUser.webAccessaRole.webAccessPermissions.find(l=>l.permissionName==module)).inverseParent.find(l=>l.permissionName==employeeSubModule)).inverseParent.find(x => x.permissionName.trim().toLocaleLowerCase() == "Employee Role".toLocaleLowerCase().trim()).inverseParent.find(p => p.permissionName.trim() == "Config".trim()).status &&
                                                    urlSpileted.length == 6))
                                            ) {
                                                return true;
                                            }
                                            else {
                                                this.router.navigate(['/pages/modules/backOffice/']);
                                                return false;
                                            }
                                        }
                                    }
                                    //#endregion
    
                                    //#region Paymrnt Mangement
    
                                    // Handeling (tax) Path 
                                    else if (urlSpileted[4] == "tax") {
                                        this.HandelingModuleRoute(module, paymentSettingsSubModule, "Tax Setup");
                                    }
                                    // Handeling (payment) Path 
                                    else if (urlSpileted[4] == "payment") {
                                        this.HandelingModuleRoute(module, paymentSettingsSubModule, "Payments Setup");
                                    }
                                    // Handeling (discount) Path 
                                    else if (urlSpileted[4] == "discount") {
                                        this.HandelingModuleRoute(module, paymentSettingsSubModule, "Discount");
                                    }
    
                                    // Handeling (currency) Path 
                                    else if (urlSpileted[4] == "currency") {
                                        this.HandelingModuleRoute(module, paymentSettingsSubModule, "Currency Setup");
                                    }
                                    // Handeling (promotion) Path 
                                    else if (urlSpileted[4] == "promotion") {
                                        this.HandelingModuleRoute(module, paymentSettingsSubModule, "Promotion");
                                    }
                                    // Handeling (promotionAddEdit) Path 
                                    else if (urlSpileted[4] == "promotionAddEdit") {
                                        if (((((this.authenticationService.currentUserSubject.value).webUser.webAccessaRole.webAccessPermissions.find(l=>l.permissionName==module)).inverseParent.find(l=>l.permissionName==paymentSettingsSubModule)).inverseParent.find(x => x.permissionName.trim().toLocaleLowerCase() == "Promotion".toLocaleLowerCase().trim())) == null) {
                                            this.router.navigate(['/pages/modules/backOffice/']);
                                            return false;
                                        }
                                        else {
                                            if ((((this.authenticationService.currentUserSubject.value.webUser.webAccessaRole.webAccessPermissions.find(l=>l.permissionName==module)).inverseParent.find(l=>l.permissionName==paymentSettingsSubModule)).inverseParent.find(x => x.permissionName.trim().toLocaleLowerCase() == "Promotion".toLocaleLowerCase().trim())).status == true) {
                                                if (((((this.authenticationService.currentUserSubject.value).webUser.webAccessaRole.webAccessPermissions.find(l=>l.permissionName==module)).inverseParent.find(l=>l.permissionName==paymentSettingsSubModule)).inverseParent.find(x => x.permissionName.trim().toLocaleLowerCase() == "Promotion".toLocaleLowerCase().trim()).inverseParent.find(p => p.permissionName.trim() == "Edit".trim()).status &&
                                                    urlSpileted.length == 6)) {
                                                    return true;
                                                }
                                                else if ((((((this.authenticationService.currentUserSubject.value).webUser.webAccessaRole.webAccessPermissions.find(l=>l.permissionName==module)).inverseParent.find(l=>l.permissionName==paymentSettingsSubModule)).inverseParent.find(x => x.permissionName.trim().toLocaleLowerCase() == "Promotion".toLocaleLowerCase().trim()).inverseParent.find(p => p.permissionName.trim() == "Add".trim()).status) && urlSpileted.length == 5)) {
                                                    return true;
                                                }
                                                else {
                                                    this.router.navigate(['/pages/modules/backOffice/']);
                                                    return false;
                                                }
                                            }
                                            else {
                                                this.router.navigate(['/pages/modules/backOffice/']);
                                                return false;
                                            }
                                        }
                                    }
                                    // Handeling (posMode) Path 
                                    else if (urlSpileted[4] == "posMode") {
                                        this.HandelingModuleRoute(module, paymentSettingsSubModule, "POS Modes");
                                    }
                                    //#endregion
    
                                    //#region Customers Mangement
    
                                    // Handeling (customer) Path 
                                    else if (urlSpileted[4] == "customer") {
                                        this.HandelingModuleRoute(module, customersSubModule, "Customers");
                                    }
                                    // Handeling (customer) Path 
                                    else if (urlSpileted[4] == "customerGroup") {
                                        this.HandelingModuleRoute(module, customersSubModule, "Customers Group");
                                    }
                                    // Handeling (customerAddEdit) Path 
                                    else if (urlSpileted[4] == "customerAddEdit") {
                                        if (((((this.authenticationService.currentUserSubject.value).webUser.webAccessaRole.webAccessPermissions.find(l=>l.permissionName==module)).inverseParent.find(l=>l.permissionName==customersSubModule)).inverseParent.find(x => x.permissionName.trim().toLocaleLowerCase() == "Customers".toLocaleLowerCase().trim())) == null) {
                                            this.router.navigate(['/pages/modules/backOffice/']);
                                            return false;
                                        }
                                        else {
                                            if ((((this.authenticationService.currentUserSubject.value.webUser.webAccessaRole.webAccessPermissions.find(l=>l.permissionName==module)).inverseParent.find(l=>l.permissionName==customersSubModule)).inverseParent.find(x => x.permissionName.trim().toLocaleLowerCase() == "Customers".toLocaleLowerCase().trim())).status == true) {
                                                if (((((this.authenticationService.currentUserSubject.value).webUser.webAccessaRole.webAccessPermissions.find(l=>l.permissionName==module)).inverseParent.find(l=>l.permissionName==customersSubModule)).inverseParent.find(x => x.permissionName.trim().toLocaleLowerCase() == "Customers".toLocaleLowerCase().trim()).inverseParent.find(p => p.permissionName.trim() == "Edit".trim()).status &&
                                                    urlSpileted.length == 6)) {
                                                    return true;
                                                }
                                                else if ((((((this.authenticationService.currentUserSubject.value).webUser.webAccessaRole.webAccessPermissions.find(l=>l.permissionName==module)).inverseParent.find(l=>l.permissionName==customersSubModule)).inverseParent.find(x => x.permissionName.trim().toLocaleLowerCase() == "Customers".toLocaleLowerCase().trim()).inverseParent.find(p => p.permissionName.trim() == "Add".trim()).status) && urlSpileted.length == 5)) {
                                                    return true;
                                                }
                                                else {
                                                    this.router.navigate(['/pages/modules/backOffice/']);
                                                    return false;
                                                }
                                            }
                                            else {
                                                this.router.navigate(['/pages/modules/backOffice/']);
                                                return false;
                                            }
                                        }
                                    }
                                    //#endregion
    
                                    //#region Configiration
    
                                    // Handeling (printer) Path 
                                    else if (urlSpileted[4] == "printer") {
                                        this.HandelingModuleRoute(module, configurationsSubModule, "Printers");
                                    }
                                    // Handeling (voidReason) Path 
                                    else if (urlSpileted[4] == "voidReason") {
                                        this.HandelingModuleRoute(module, configurationsSubModule, "Void Reasons");
                                    }
                                    // Handeling (invoiceMessage) Path 
                                    else if (urlSpileted[4] == "invoiceMessage") {
                                        this.HandelingModuleRoute(module, configurationsSubModule, "Invoice Message");
                                    }
                                    // Handeling (workStation) Path 
                                    else if (urlSpileted[4] == "workStation") {
                                        this.HandelingModuleRoute(module, configurationsSubModule, "Workstations");
                                    }
                                    // Handeling (workStationAddEdit) Path 
                                    else if (urlSpileted[4] == "workStationAddEdit") {
                                        this.HandelingModuleRoute(module, configurationsSubModule, "Workstations");
                                        if ((((this.authenticationService.currentUserSubject.value.webUser.webAccessaRole.webAccessPermissions.find(l=>l.permissionName==module)).inverseParent.find(l=>l.permissionName==configurationsSubModule)).inverseParent.find(x => x.permissionName.trim().toLocaleLowerCase() == "Workstations".toLocaleLowerCase().trim())).status == true) {
                                            if (((((this.authenticationService.currentUserSubject.value).webUser.webAccessaRole.webAccessPermissions.find(l=>l.permissionName==module)).inverseParent.find(l=>l.permissionName==configurationsSubModule)).inverseParent.find(x => x.permissionName.trim().toLocaleLowerCase() == "Workstations".toLocaleLowerCase().trim()).inverseParent.find(p => p.permissionName.trim() == "Edit".trim()).status &&
                                                urlSpileted.length == 6)) {
                                                return true;
                                            }
                                            else if ((((((this.authenticationService.currentUserSubject.value).webUser.webAccessaRole.webAccessPermissions.find(l=>l.permissionName==module)).inverseParent.find(l=>l.permissionName==configurationsSubModule)).inverseParent.find(x => x.permissionName.trim().toLocaleLowerCase() == "Workstations".toLocaleLowerCase().trim()).inverseParent.find(p => p.permissionName.trim() == "Add".trim()).status) && urlSpileted.length == 5)) {
                                                return true;
                                            }
                                            else {
                                                this.router.navigate(['/pages/modules/backOffice/']);
                                                return false;
                                            }
                                        }
                                        else {
                                            this.router.navigate(['/pages/modules/backOffice/']);
                                            return false;
                                        }
                                    }
    
                                    // Handeling (posMap) Path 
                                    else if (urlSpileted[4] == "posMap") {
                                        this.HandelingModuleRoute(module, configurationsSubModule, "POS Maps");
                                    }
                                    // Handeling (posMapAddEdit) Path 
                                    else if (urlSpileted[4] == "posMapAddEdit") {
                                        if ((((this.authenticationService.currentUserSubject.value.webUser.webAccessaRole.webAccessPermissions.find(l=>l.permissionName==module)).inverseParent.find(l=>l.permissionName==configurationsSubModule)).inverseParent.find(x => x.permissionName.trim().toLocaleLowerCase() == "POS Maps".toLocaleLowerCase().trim())).status == true) {
                                            if (((((this.authenticationService.currentUserSubject.value).webUser.webAccessaRole.webAccessPermissions.find(l=>l.permissionName==module)).inverseParent.find(l=>l.permissionName==configurationsSubModule)).inverseParent.find(x => x.permissionName.trim().toLocaleLowerCase() == "POS Maps".toLocaleLowerCase().trim()).inverseParent.find(p => p.permissionName.trim() == "Edit".trim()).status &&
                                                urlSpileted.length == 6)) {
                                                return true;
                                            }
                                            else if ((((((this.authenticationService.currentUserSubject.value).webUser.webAccessaRole.webAccessPermissions.find(l=>l.permissionName==module)).inverseParent.find(l=>l.permissionName==configurationsSubModule)).inverseParent.find(x => x.permissionName.trim().toLocaleLowerCase() == "POS Maps".toLocaleLowerCase().trim()).inverseParent.find(p => p.permissionName.trim() == "Add".trim()).status) && urlSpileted.length == 5)) {
                                                return true;
                                            }
                                            else {
                                                this.router.navigate(['/pages/modules/backOffice/']);
                                                return false;
                                            }
                                        }
                                        else {
                                            this.router.navigate(['/pages/modules/backOffice/']);
                                            return false;
                                        }
                                    }
                                    //#endregion
    
                                    //#region Reports
    
                                    // Handeling (salesByInvoice) Path 
                                    else if (urlSpileted[4] == "salesByInvoice") {
                                        this.HandelingModuleRoute(module, reportsSubModule, "Sales By Invoice");
                                    }
                                    // Handeling (salesByMenu) Path 
                                    else if (urlSpileted[4] == "salesByMenu") {
                                        this.HandelingModuleRoute(module, reportsSubModule, "Sales By Menu");
                                    }
                                    // Handeling (salesByCustomer) Path 
                                    else if (urlSpileted[4] == "salesByCustomer") {
                                        this.HandelingModuleRoute(module, reportsSubModule, "Sales By Customer");
                                    }
                                    // Handeling (financialReports) Path 
                                    else if (urlSpileted[4] == "financialReports") {
                                        this.HandelingModuleRoute(module, reportsSubModule, "Financial Reports");
                                    }
                                    // Handeling (financialReports) Path 
                                    else if (urlSpileted[4] == "financialReports") {
                                        this.HandelingModuleRoute(module, reportsSubModule, "Financial Reports");
                                    }
                                    // Handeling (discountReport) Path 
                                    else if (urlSpileted[4] == "discountReport") {
                                        this.HandelingModuleRoute(module, reportsSubModule, "Discount Reports");
                                    }
                                    // Handeling (advanced) Path 
                                    else if (urlSpileted[4] == "advancedReport") {
                                        this.HandelingModuleRoute(module, reportsSubModule, "Advanced  Reports");
                                    }
                                    //#endregion
                                    else { return true;  }
                                }
                            }
                            else if (urlSpileted[4] == "webAccessPermission" || urlSpileted[4] == "webUser" || urlSpileted[4] == "accessRole"
                                || urlSpileted[4] == "webUser" || urlSpileted[4] == "profile" || urlSpileted[4] == "zone" ||
                                urlSpileted[4] == "city" || urlSpileted[4] == "company" || urlSpileted[4] == "branch" ||
                                urlSpileted[4] == "deliveryCharge" || urlSpileted[4] == "barnchConfig") {
                                return true;
                            }
                            else {
                                this.router.navigate(['/pages/modules/home/']);
                                return false;
                            }
                        }
                        else {
                            this.router.navigate(['/pages/modules/home/']);
                            return false;
                        }
                    }
                    else if (urlSpileted[3] == "dashboard") {
                        if (this.authenticationService.currentUserValue.webUser.webAccessaRole.webAccessPermissions.find(l=>l.permissionName=="Dashboard"))
                        return true;
                        else
                        {
                            this.router.navigate(['/pages/modules/home/']);
                            return false;
                        }

                    }
                    else if (urlSpileted[3] == "home") {
                        return true;
                    }
                }
                else if (urlSpileted[3] == "home") {
                    true;
                }
                else {
                    this.authenticationService.signup().subscribe(
                        res => {
                            this.router.navigate(['/sign-in'], { queryParams: { returnUrl: state.url } });
                        },
                        error => { this.router.navigate(['/sign-in']); }
                    );
                    return false;
                }
            }
            // authorised so return true
            return true;
        }

    }

    private HandelingModuleRoute(module: any, subModule: any, page: string): boolean 
    {

        if (((((this.authenticationService.currentUserSubject.value).webUser.webAccessaRole.webAccessPermissions.find(l=>l.permissionName==module)).inverseParent.find(l=>l.permissionName==subModule)).inverseParent.find(x => x.permissionName.trim().toLocaleLowerCase() == page.toLocaleLowerCase().trim())) == null) {
            this.router.navigate(['/pages/modules/backOffice/']);
            return false;
        }
        else {
            if (((((this.authenticationService.currentUserSubject.value).webUser.webAccessaRole.webAccessPermissions.find(l=>l.permissionName==module)).inverseParent.find(l=>l.permissionName==subModule)).inverseParent.find(x => x.permissionName.trim().toLocaleLowerCase() == page.toLocaleLowerCase().trim())).status == true) {
                return true;
            }
            else {
                this.router.navigate(['/pages/modules/backOffice/']);
                return false;
            }
        }
    }

    
}

