import { Component, OnInit } from '@angular/core';
import { SelectionModel } from '../../../../../../../shared/models/selection-model';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../../../../../shared/services/authentication.service';
import { TosterService } from '../../../../../../../shared/services/toster.service';
import { CompanyService } from '../../../company/services/company.service';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ConfirmationComponent } from '../../../../../../../shared/components/confirmation/confirmation/confirmation.component';
import { CurrentCompany } from '../../../../../../../shared/components/auth/signin/models/current-company';
import { CurrentYear } from '../../../../../../../shared/components/auth/signin/models/current-year';
import { WarningComponent } from '../../../../../../../shared/components/warning/warning.component';

@Component({
  selector: 'ngx-change-company',
  templateUrl: './change-company.component.html',
  styleUrls: ['./change-company.component.scss']
})
export class ChangeCompanyComponent implements OnInit {

  //#region  Variable

  form: FormGroup;
  loading: boolean = false;
  companyList: SelectionModel[];


  //#endregion
  constructor(private dialog: NbDialogRef<any>, private dialogService: NbDialogService, private companyService: CompanyService, private authenticationService: AuthenticationService, private router: Router, private tosterService: TosterService) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      companyId: new FormControl(null),
    });
    this.companyList = this.authenticationService.currentCompanyValue.webUserAllowedCompanies;
  }

  companySelectedChange(event) {
    if (event != this.authenticationService.currentCompanyValue.company.id) {
      var changedCompany = this.companyList.find(l => l.id == event);
      this.dialogService.open(ConfirmationComponent,
        {
          context: { message: "Are you sure you want to change company to " + changedCompany.name + " ?" },
          closeOnBackdropClick: false, autoFocus: true, dialogClass: 'model-full'
        })
        .onClose.subscribe(rep => {
          if (rep) {
            this.loading = true;
            this.companyService.changeCompany(event, this.authenticationService.currentUserValue.webUser.id).subscribe(
              res => {
                if (res.code == 302) {
                  var  yearObj = new CurrentYear();
                  var companyObj = new CurrentCompany();
                  yearObj.year=res.data.currentYear;
                  companyObj.company = res.data.currentCompany;
                  companyObj.webUserAllowedCompanies =this.authenticationService.currentCompanyValue.webUserAllowedCompanies;
                  this.authenticationService.setCurrentCompanyValue=companyObj;
                  this.authenticationService.setCurrentYearValue=yearObj;
                   this.close(null);
                  this.router.navigate(['pages/modules/home']);
                } else if (res.code == 204) {
                  this.tosterService.showToast('warning', res.message);
                }
                this.loading = false;
              }, error => this.HandelingError(error)
            );
          }
        });

    }else this.callWarningComponent('You are in the current Company !')
  }

  callWarningComponent(message)
  {
  this.dialogService.open(WarningComponent,
    {
      context: { message: message },
      closeOnBackdropClick: false, autoFocus: true, dialogClass: 'model-full'
    })
  }

  HandelingError(error: string) {
    if (error.includes("401") || error.includes("500") || error.includes("0")) {
      this.tosterService.showToast("warning", "Your Session Has Expired Please Login In");
      setTimeout(() => {
        this.authenticationService.Logout();
        this.router.navigate(['/sign-in']);
      }, 3000);
    }
  }

  close(data) {
    this.dialog.close(data);
  }
}
