<nb-card accent="warning" style="min-width: 400px">
    <nb-card-body>
        <h6 style="margin: 5%;" [innerHTML]="message">  </h6>
    </nb-card-body>
    <nb-card-footer>
        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6">
                <button nbButton size="small" status="warning" fullWidth shape="semi-round" (click)="close(true)">
                    OK
                </button>
            </div>
            <div class="col-md-3"></div>

        </div>
    </nb-card-footer>
</nb-card>