import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-warning',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.scss']
})
export class WarningComponent implements OnInit {


 //#region Variable
 @Input() message: string;

 //#endregion

 constructor(private dialog: NbDialogRef<string>) { }

 ngOnInit(): void {
 
 }

 onSubmit() {


   
 }

 close(data){
   this.dialog.close(data);
 }
}


